import ruLocal from 'element-ui/lib/locale/lang/ru-RU'
import ac_ru from "./activity/ac_ru";
const ru = {
    400000: 'Академия',
    400001: 'Новости',
    400002: 'Дата',
    400003: 'Видео',
    400004: 'Академия',
    400005: 'Войти',
    400006: 'Регистрация',
    400007: 'Кошельки',
    400008: 'Обзор кошелька',
    400009: 'Спотовый',

    400010: 'P2P Аккаунт',
    400011: 'Фьючерсный',
    400012: 'История кошелька',
    400013: 'Спотовая сделка',
    400014: 'Фьючерсный ордер',
    400015: 'Обзор',
    400016: 'Проверка личности',
    400017: 'Безопасность аккаунта',
    400018: 'Реферал',
    400019: 'Предпочтения',

    400020: 'Центр купонов',
    400021: 'Выход',
    400022: '<em class="normal">{num}</em>новых уведомлений',
    400023: 'Очистить все',
    400024: 'Просмотреть больше',
    400025: 'Записей не найдено',
    400026: 'Сканируй, чтобы загрузить приложение',
    400027: 'Больше загрузок',
    400028: 'Детали уведомления',
    400029: 'Я понимаю',

    400030: 'P2P',
    400031: 'Рынки',
    400032: 'Спот',
    400033: 'Фьючерс',
    400034: 'Super Start',
    400035: 'Адрес не существует',
    400036: 'Самая безопасная платформа для торговли цифровыми активами в мире！',
    400037: 'О нас',
    400038: 'Условия',
    400039: 'Конфиденциальность',

    400040: 'Объявления',
    400041: '学习',
    400042: 'Центр новичков',
    400043: 'Спот',
    400044: 'Фьючерсы',
    400045: 'FAQ',
    400046: 'Обслуживание',
    400047: 'Сборы',
    400048: 'Правила',
    400049: 'Листинговая заявка',

    400050: 'Реферал',
    400051: 'Связаться с нами',
    400052: 'Центр поддержки',
    400053: 'Отправить запрос',
    400054: 'Бизнес контакты',
    400055: 'Институциональное сотрудничество',
    400056: 'Язык и регион',
    400057: 'Валюта',
    400058: 'Выбрать язык и регион',
    400059: 'Выбрать курс обмена',

    400060: 'Перевод',
    400061: 'На внутренние трансферы Superex не взимает комиссию',
    400062: 'Из',
    400063: 'к',
    400064: 'Пара',
    400065: 'Скидка комиссии',
    400066: 'Пересечь',
    400067: 'Изолирован',
    400068: 'Разделить',
    400069: 'Баланс',

    400070: 'Криптовалюта',
    400071: 'Поиск',
    400072: 'Сумма',
    400073: 'Доступно',
    400074: 'Все',
    400075: 'Подтвердить перевод',
    400076: 'Открыть',
    400077: 'Попробовать позже',
    400078: 'Открыть фьючерсный счет',
    400079: 'Вы еще не открыли фьючерсный счет, поэтому перевод временно недоступен.',

    400080: '{timeout} s',
    400081: 'Отправить код',
    400082: 'Все криптовалюты',
    400083: 'Все фиатные валюты',
    400084: 'Все статусы',
    400085: 'Дополнительно',
    400086: 'Третья сторона',
    400087: 'Руководство',
    400088: 'Ордеры',
    400089: 'P2P Мерчант Центр',

    400090: 'Центр пользователей P2P',
    400091: 'Способ оплаты',
    400092: 'Разместить новое объявление',
    400093: 'Мои объявления',
    400094: 'Больше',
    400095: 'Подать заявку',
    400096: 'Отменить',
    400097: 'Вы временно не можете публиковать объявления',
    400098: 'Сначала подайте заявку, чтобы стать рекламодателем',
    400099: 'Пройти верификацию',

    400100: 'Неверифицирован',
    400101: 'Вы не завершили верификацию личности',
    400102: 'В целях безопасности вашего аккаунта и других торговых пользователей, сначала пройдите верификацию личности',
    400103: 'Установить никнейм',
    400104: 'Установите никнейм вашего аккаунта. Рекомендуется не использовать ваше настоящее имя. Длина никнейма составляет 2~10 символов.',
    400105: 'Извините, в данный момент вы не можете подать заявку на получение статуса продавца.',
    400106: 'Установка успешна',
    400107: 'Преимущества SuperEx P2P',
    400108: '0 Комиссия',
    400109: 'Торгуйте биткоином и другими криптовалютами легко и быстро с нулевой комиссией на стороне ордера на SuperEx P2P!',

    400110: 'Удобные способы оплаты',
    400111: 'SuperEx P2P позволяет продавцам свободно выбирать способы оплаты.',
    400112: 'Торгуйте по лучшим ценам',
    400113: 'SuperEx P2P позволяет пользователям устанавливать собственные цены и выбирать предпочтительную цену для покупки и продажи криптовалюты.',
    400114: 'Безопасная торговля криптовалютами',
    400115: 'SuperEx P2P использует механизм вывода наличных T+n, строго управляет торговцами и проверяет подозрительные транзакции с помощью больших данных контроля рисков, чтобы обеспечить безопасность активов платформы, а пользователи могли торговать безопасно.',
    400116: '{amount} Объем',
    400117: '{num}% Коэффициент транзакций',
    400118: 'Цена',
    400119: 'Срок оплаты',

    400120: 'Способ оплаты продавца',
    400121: 'Условия сделки',
    400122: 'Я заплачу',
    400123: 'Я получу',
    400124: 'Я хочу продать',
    400125: 'Способ оплаты',
    400126: 'Настройте метод получения',
    400127: 'Добавить способ оплаты',
    400128: 'Справочная цена единицы {price}',
    400129: 'Купить',

    400130: 'Продать',
    400131: 'В целях защиты сохранности средств для купленных активов будет введен лимит на снятие средств {limit}.',
    400132: 'Узнать больше',
    400133: 'Хорошо',
    400134: 'В соответствии со страной вашего сертификата и рабочими характеристиками платформы, пожалуйста, переключитесь на местную валюту для транзакций.',
    400135: 'Лимит покупки: {min}-{max} {fiat}',
    400136: 'Лимит продажи: {min}-{max} {fiat}',
    400137: 'Рекламодатели',
    400138: 'Лимит / Количество',
    400139: 'Торговля',

    400140: 'Сумма',
    400141: 'Лимит',
    400142: 'Обработать',
    400143: 'Хорошо',
    400144: 'Прежде чем начать торговлю, пожалуйста, пройдите идентификацию личности',
    400145: 'Число отмен ваших ордеров сегодня превысило {num} раз, и вы не можете продолжать торговлю. Пожалуйста, повторите попытку после {time}.',
    400146: 'Вы достигли максимального количества невыполненных ордеров ({num}), пожалуйста, сначала выполните текущий ордер.',
    400147: 'Этот рекламодатель достиг максимального количества невыполненных заказов, пожалуйста, зайдите позже или попробуйте другое объявление.',
    400148: 'Вы не выполнили требования рекламодателя, пожалуйста, попробуйте другое объявление.',
    400149: 'Выбирать',

    400150: 'Как работает P2P',
    400151: 'Купить криптовалюту',
    400152: 'Продать криптовалюту',
    400153: 'Разместить ордер',
    400154: 'После размещения ордера, средства продавца для этой сделки будут управляться SuperEx P2P.',
    400155: 'Оплата продавцу',
    400156: 'Отправьте деньги продавцу с помощью способа оплаты в информации о транзакции и нажмите "Оплачено, уведомить продавца" на SuperEx P2P после завершения перевода.',
    400157: 'Получите свою криптовалюту',
    400158: 'Как только продавец подтвердит получение платежа, хранящаяся криптовалюта будет выдана вам.',
    400159: 'Подтвердить платеж',

    400160: 'Проверьте историю транзакций в вашем аккаунте и убедитесь, что вы получили платеж от покупателя.',
    400161: 'Отправить криптовалюту',
    400162: 'Отправьте криптовалюту покупателям на SuperEx P2P после подтверждения получения оплаты.',
    400163: 'Сумма',
    400164: 'Введите сумму',
    400165: 'Фиатная валюта',
    400166: 'Все способы оплаты',
    400167: 'Идентификатор объявления',
    400168: 'Подтвердить',
    400169: 'Вы уверены, что хотите удалить это объявление?',

    400170: '1. Пользователи, удаляющие объявления, не смогут торговать с вами через объявления.',
    400171: '2. Удаление объявлений не влияет на текущие ордеры',
    400172: 'Вы уверены, что хотите удалить это объявление?',
    400173: 'Изменить',
    400174: 'Удалить',
    400175: 'Добавлено',
    400176: 'Удалено',
    400177: 'Создано',
    400178: 'Минимальный лимит',
    400179: 'Оставшаяся сумма/Сумма ордера',

    400180: 'Цена ордера',
    400181: 'Тип',
    400182: 'Листинг успешный',
    400183: 'Все типы',
    400184: 'Статус',
    400185: 'Идентификатор объявления/валюта/дата',
    400186: 'Действовать',
    400187: 'В соответствии с условиями работы страны или региона, где сертифицирована ваша личность SuperEx, и платформы, пожалуйста, выберите поддерживаемую законную валюту для транзакции.',
    400188: 'Не соответствует требованиям рекламодателя',
    400189: 'Удалено',

    400190: 'Удалено успешно',
    400191: 'Разместить объявление',
    400192: 'Изменить объявление',
    400193: 'Фиксированная цена',
    400194: 'Плавающая цена',
    400195: 'Процент изменения цены',
    400196: 'Пожалуйста входите',
    400197: 'Торговая цена',
    400198: 'Рыночная справочная цена',
    400199: 'Установка плавающего процента позволяет вам покупать и продавать на процент выше или ниже реальной рыночной справочной цены.',

    400200: 'Фиксированные цены не меняются при колебаниях рынка',
    400201: 'Торговые ограничения',
    400202: 'Введите объем транзакции',
    400203: 'Количество сделок',
    400204: 'Минимальный единичный лимит',
    400205: 'Введите минимальный лимит на одну транзакцию',
    400206: 'Максимальный единичный лимит',
    400207: 'Введите максимальный лимит на одну транзакцию',
    400208: 'Максимум',
    400209: 'Объявление требует комиссии около {rate}% {currency}, в настоящее время доступно {available} {currency}.',

    400210: 'Способ оплаты',
    400211: 'Выберите до 3',
    400212: 'В данный момент нет способа оплаты',
    400213: 'Выберите срок оплаты',
    400214: 'Введите содержимое',
    400215: 'Пользовательские условия торговли',
    400216: 'Увеличение лимита торговых пользователей снизит вероятность показа вашего объявления',
    400217: 'Завершите регистрацию',
    400218: 'дня спустя',
    400219: 'Запустить сейчас',

    400220: 'Запустить позже',
    400221: 'Раскрытие информации о рисках',
    400222: 'Вы должны ввести номер',
    400223: 'Выберите способ оплаты',
    400224: 'Введите плавающую цену',
    400225: 'Введите фиксированную цену',
    400226: 'Цена отклонилась от рыночной на {num}%, продолжение публикации будет рискованным, подтвердите, стоит ли продолжать публикацию объявлений?',
    400227: 'Максимальный единичный лимит должен быть больше минимального единичного лимита',
    400228: 'Максимальный на одну транзакцию не должен быть выше {num} {fiat}.',
    400229: 'Минимальный лимит на одну транзакцию не должен быть меньше {num} {fiat}.',

    400230: 'Минимальный лимит одиночного ордера должен быть меньше максимального лимита одиночного ордера',
    400231: 'Максимальная сумма транзакции не должна быть больше {num} {currency}.',
    400232: 'Публикация успешна',
    400233: 'Торговля P2P не открыта для авторизованных пользователей кошелька в настоящее время, пожалуйста, используйте свой аккаунт SuperEx для входа и торговли P2P',
    400234: 'Сеть DAO',
    400235: 'Roadmap',
    400236: 'Пригласите друзей',
    400237: 'Центр поддержки',
    400238: 'База Airdrop ',
    400239: 'Mainnet Explore',

    400240: 'Testnet Explore',
    400241: 'Документы для разработчиков',
    400242: 'Центр событий',
    400243: 'Используя сайты SuperEx и/или поддерживая свою аккаунт, вы соглашаетесь с нашими обновленными <a style="color: inherit;text-decoration: underline;" target="_blank" href="https://support.superex.com/hc/en-001/articles/11479008598553-Terms-of-Service">Условиями использования</a>, <a style="color: inherit;text-decoration: underline;" target="_blank" href="https://support.superex.com/hc/en-001/articles/11478863478809-Privacy-Statement">Заявление о конфиденциальности</a> и <a style="color: inherit;text-decoration: underline;" target="_blank" href="https://support.superex.com/hc/en-001/articles/20761721369241">правилами использования файлов cookie</a>. Нажимая "X", вы подтверждаете, что ознакомились и согласились с вышеупомянутыми Условиями и Правилами.',
    400244: 'Купить Tether | Получить USDT | SuperEx P2P',
    400245: 'Центр задач',
    400246: 'Валюта',
    400247: 'Получить',
    400248: 'Расходы',
    400249: 'Доступно',

    400250: 'Платить',
    400251: 'Реквизиты заказа',
    400252: 'Сумма (включая комиссию за транзакцию)',
    400253: 'Время прибытия',
    400254: '≈{time} минут',
    400255: 'Уведомление',
    400256: '1.您将离开SUPEREX并进入第三方AlchemyPay网页以完成您的订单。',
    400257: '2. 订单完成后，您可以前往您的 <span class="pointer">财务记录</span> 查看详细信息。',
    400258: '3. 如果您需要付款方面的帮助，请联系付款提供商的 <span class="pointer">客户支持</span>。',
    400259: '4. 更多详情请参阅常见问题解答。 <span class="pointer">常问问题</span>',

    400260: 'Я прочитал и согласен с дисклеймером',
    400261: 'Как купить криптовалюту на SuperEx?',
    400262: 'Как продать криптовалюту на SuperEx？',
    400263: 'Разместить заказ на покупку криптовалюты',
    400264: 'Выберите фиатную валюту, которой вы хотите расплатиться, и криптовалюту, которую вы хотите купить. Введите сумму, которую вы хотите потратить.',
    400265: 'Верифицируйте свою личность',
    400266: 'Пройдите KYC-верификацию на SuperEx и у платежного провайдера, чтобы завершить платеж.',
    400267: 'Проверьте детали заказа',
    400268: 'Проверьте историю пополнения счета, чтобы узнать статус заказа.',
    400269: '1. Какие способы оплаты я могу использовать для покупки криптовалюты?',

    400270: 'В настоящее время SuperEx поддерживает платежные системы VISA, Mastercard, Apple Pay, Google Pay и другие способы оплаты. Среди поддерживаемых сторонних поставщиков услуг - Mercuryo и alchemypay.',
    400271: '2. Какие криптовалюты я могу приобрести?',
    400272: 'SuperEx поддерживает основные криптовалюты, такие как BTC, ETH, USDT, LTC, USDC, XRP, BCH, CRV и TRX.',
    400273: '3. Контролирует ли SuperEx качество и цены поддерживаемых сторонних поставщиков услуг?',
    400274: 'SuperEx тщательно отобрал ряд поставщиков услуг с надежной репутацией в отрасли, популярностью и безопасностью, чтобы обеспечить пользователей удобными услугами.',
    400275: '4. Какую комиссию я должен заплатить за покупку криптовалюты?',
    400276: 'Вы платите комиссию стороннему поставщику услуг и комиссию за перевод на цепочку. SuperEx не взимает никаких комиссий.',
    400277: '5. Сколько времени занимает получение криптовалюты после оплаты?',
    400278: 'После завершения платежа на платформе стороннего поставщика услуг криптовалюта будет зачислена на ваш спот-счет на SuperEx примерно через 2-10 минут.',
    400279: '6. Что делать, если у меня возникнут проблемы в процессе покупки?',

    400280: 'Обратитесь в службу поддержки, если у вас возникнут какие-либо проблемы в процессе транзакции. Если вы не получили криптовалюту после завершения платежа, свяжитесь со сторонним поставщиком услуг для проверки деталей заказа (обычно это наиболее эффективный метод). В связи с IP вашего текущего региона или по определенным политическим причинам вам придется выбрать верификацию человеком.',
    400281: 'Лимит на одну транзакцию:',
    400282: 'Введите объем',
    400283: 'Поставщик платежа',
    400284: 'Получить',
    400285: 'Недостаточный баланс',
    400286: "Вы покидаете SuperEx и переходите на <span>{name}</span>, лицензированное финансовое учреждение, предоставляющее возможности оплаты картами в качестве третьей стороны. Все риски, возникающие в процессе использования, несет <span>{name}</span>. Пожалуйста, прочитайте и согласитесь с <a class= 'pointer' target='_blank' href={server}>Соглашением об обслуживании</a> компании <span>{name}</span>, прежде чем воспользоваться ее услугами.Если у Вас возникли вопросы по поводу пополнения счета кредитной картой, пожалуйста, свяжитесь с <a class='pointer' target='_blank' href={Customer}>{name} Customer Service</a> для получения более подробной информации.",
    400287: 'Отказ от ответственности',
    400289: 'Доступен спотовый счет',
    400290: 'Подтвердить',
    400291: 'Зона ET',


    400293: 'https://support.superex.com/hc/en-001/articles/23084120565017',
    400294: 'Купить',
    // криптовалюту

    400295:'Откройте Safari для использования Apple Pay',
    400288: 'Финансы',

    400292:'Финансовый счет',


    410000:'SCS Аирдроп',
    410001:'Агенты',

    410002:'Финансы',
    100756: 'Получить',
    100219: 'Активночть',
    101154: 'История подписки',



    122000:"Глобальная сеть DAO и ссылки на социальные сети",
    122001:"Приглашайте друзей, чтобы получать бонусы",
    122002:"План развития SuperEx",
    122003:"Решение распространенных проблем, возникающих в процессе использования",
    122004:"Выполнение заданий для получения бонусов",
    122005:"Введение в ET",
    122006:"Введение в SuperEx",
    122007:"Комиссия за привлечение агента",
    122008:"Низкая комиссия при покупке криптовалюты",
    122009:"Быстрая покупка криптовалюты",
    122010:"Рынок торговли криптовалютой",
    122011:"Рынок спот-торговли",
    122012:"Рынок торговли фьючерсами",
    122013:"Платформа IEO для проектов с высокой стоимостью",
    122014:"Накопление денег и управление ими для получения дохода",
    122015:"Покупки по сверхнизким ценам",
    122016:"Центр интересных событий",
    122017:"Продвигайтесь вперед в экосистеме BTC с новыми монетами",
    122018:"Центр задач SCS Airdrop",
    122019:"Официальный сайт SCS Chain",
    122020:"SCS Chain Браузер",
    122021:"Браузер тестовой сети SCS Chain",
    122022:"Документация для разработчиков",
    122023:"Просмотреть последние новости",
    122024:"Быстрый обзор последних событий",
    122025:"Посмотреть SuperEx на YouTube",
    122026:"Узнать все о блокчейне",
    122027:"Откройте загадочную коробку вместе со своими друзьями",
    122028: 'Загадочная коробка',

    //合约积分
    9100007: 'Торговые очки',
    9100031: 'Центр активности торговых очков',

    9100032: 'Количественные фонды',
    9100033: 'Профессиональное управление активами',

    8100000: 'Отзывы',


    'target_1': 'Спот',
    'target_2': 'P2P Аккаунт',
    'target_3': '币本位合约账户',
    'target_4': 'USDⓢ-M',
    'target_5': '超级合约账户',
    'target_6': 'Финансы - Доступные проценты',

    ...ac_ru,
    ...ruLocal
}
export default ru;
