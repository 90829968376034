import esLocal from 'element-ui/lib/locale/lang/es'
import ac_es from "./activity/ac_es";

const es = {
    400000: 'Academia',
    400001: 'Noticias',
    400002: 'Información',
    400003: 'Videos',
    400004: 'Academia',
    400005: 'Iniciar sesión',
    400006: 'Registrarse ',
    400007: 'Billeteras',
    400008: 'Descripción general de la cartera',
    400009: 'Spot',

    400010: 'Cuenta P2P',
    400011: 'Futuros',
    400012: 'Historial de carteras',
    400013: 'Órdenes spot',
    400014: 'Órdenes de Futuros',
    400015: 'Perspectiva general',
    400016: 'Verificación de identidad ',
    400017: 'Seguridad de la cuenta',
    400018: 'Recomendación ',
    400019: 'Preferencias',

    400020: 'Centro de vales',
    400021: 'Cerrar Sesión ',
    400022: '<em class="normal">{num}</em> Notificaciones pendientes',
    400023: 'Limpiar todo',
    400024: 'Ver más',
    400025: 'Sin datos',
    400026: 'Escanea para descargar la aplicación',
    400027: 'Más descargas',
    400028: 'Detalles de la notificación',
    400029: 'Entiendo',

    400030: 'P2P',
    400031: 'Mercados',
    400032: 'Spot',
    400033: 'Futuros',
    400034: 'Super Start',
    400035: 'La dirección no existe',
    400036: '¡La plataforma de comercio de activos digitales más segura del mundo!',
    400037: 'Acerca de nosotros ',
    400038: 'Términos ',
    400039: 'Privacidad',

    400040: 'Anuncios',
    400041: '学习',
    400042: 'Centro de Principiantes',
    400043: 'Comercio spot',
    400044: 'Futuros',
    400045: 'FAQ',
    400046: 'Servicio',
    400047: 'Tasas',
    400048: 'Reglas',
    400049: 'Aplicación de Listado',

    400050: 'Recomendación ',
    400051: 'Contáctenos ',
    400052: 'Centro de Ayuda',
    400053: 'Enviar una solicitud',
    400054: 'Contactos comerciales',
    400055: 'Cooperación institucional',
    400056: 'Idioma y Región ',
    400057: 'Moneda',
    400058: 'Selecciona Idioma y Región ',
    400059: 'Selecciona Tarifa de Cambio',

    400060: 'Transferencia',
    400061: 'Las transferencias internas de SuperEx no cobran comisiones',
    400062: 'De',
    400063: 'A',
    400064: 'Par',
    400065: 'Descuento en la tarifa',
    400066: 'Cruzado',
    400067: 'Aislado',
    400068: 'Dividido',
    400069: 'Balance',

    400070: 'Criptomoneda',
    400071: 'Búsqueda',
    400072: 'Cantidad',
    400073: 'Disponible',
    400074: 'Todo',
    400075: 'Confirmar transferencia',
    400076: 'Abrir',
    400077: 'Pruébalo más tarde',
    400078: 'Abrir cuenta de futuros',
    400079: 'Aún no has abierto la cuenta de futuros, por lo que la transferencia no está disponible temporalmente.',

    400080: 'Reenviar en {timeout} segundos',
    400081: 'Enviar código',
    400082: 'Todas las criptomonedas',
    400083: 'Todas las monedas fiat',
    400084: 'Todo el estado',
    400085: 'Área opcional',
    400086: 'Tercera persona',
    400087: 'Línea Guía ',
    400088: 'Órdenes',
    400089: 'Centro Comercial P2P',

    400090: 'Centro de usuarios de P2P',
    400091: 'Método de pago',
    400092: 'Publicar un nuevo anuncio',
    400093: 'Mis anuncios',
    400094: 'Más',
    400095: 'Aplicar',
    400096: 'Cancelar',
    400097: 'No puedes publicar anuncios temporalmente',
    400098: 'Aplica para convertirte en anunciante primero',
    400099: 'Obtener la Verificación',

    400100: 'Sin verificar',
    400101: 'No has completado la verificación de identidad',
    400102: 'Para la seguridad de su cuenta con otros usuarios comerciales, complete primero la verificación de identidad',
    400103: 'Establecer el apodo',
    400104: 'Establece el apodo de tu cuenta, se recomienda no usar tu nombre real',
    400105: 'Lo sentimos, no puedes solicitar convertirse en comerciante en este momento.',
    400106: 'Configuración exitosa',
    400107: 'Beneficios de SuperEx P2P',
    400108: '0 Tarifa',
    400109: 'En SuperEx P2P, puede intercambiar bitcoins y otras monedas digitales de manera conveniente y rápida, y la parte que realiza el pedido comercia sin tarifas.',

    400110: 'Métodos de pago flexibles',
    400111: 'SuperEx P2P permite a los vendedores elegir libremente los métodos de pago.',
    400112: 'Opera a mejores precios',
    400113: 'SuperEx P2P permite a los usuarios establecer sus propios precios y elegir su precio preferido para comprar y vender criptomonedas.',
    400114: 'Comercio seguro de criptomonedas',
    400115: 'SuperEx P2P adopta el mecanismo de retiro de efectivo T+n, gestiona estrictamente a los comerciantes y examina las transacciones sospechosas con big data de control de riesgos para garantizar la seguridad de los activos de la plataforma y que los usuarios puedan operar de forma segura.',
    400116: '{amount} Volumen',
    400117: '{num} %Tasa de transacción',
    400118: 'Precio',
    400119: 'Límite de tiempo para el pago',

    400120: 'Método de pago del vendedor',
    400121: 'Términos de la transacción',
    400122: 'Pagaré',
    400123: 'Recibiré',
    400124: 'Quiero venderlo',
    400125: 'Método de pago',
    400126: 'Configurar el método de recogida',
    400127: 'Añadir método de pago',
    400128: 'Precio unitario de referencia {price}',
    400129: 'Comprar',

    400130: 'Vender',
    400131: 'Con el fin de proteger la seguridad de los fondos, habrá un límite de retiro {limit} para los activos comprados',
    400132: 'Más información',
    400133: 'De acuerdo con el país de su certificado y las especificaciones operativas de la plataforma, cambie a su moneda local para las transacciones',
    400135: 'Límite de compra: {min}-{max} {fiat}',
    400136: 'Límite de venta: {min}-{max} {fiat}',
    400137: 'Anunciantes',
    400138: 'Límite / Cantidad',
    400139: 'Comercio',

    400140: 'Cantidad',
    400141: 'Limitación',
    400142: 'Proceso',
    400143: 'Ok',
    400144: 'Antes de comenzar a operar, complete la verificación de identidad',
    400145: 'Las cancelaciones de sus órdenes de hoy han superado las {num} veces, y no puede continuar operando. Por favor, inténtelo de nuevo después de {time}',
    400146: 'Ha alcanzado el número máximo de pedidos pendientes ({num}), complete primero el pedido en curso.',
    400147: 'Este anunciante ha alcanzado el número máximo de pedidos pendientes, por favor, opere más tarde o pruebe con otro anuncio.',
    400148: 'No ha cumplido con los requisitos del anunciante, pruebe con otro anuncio.',
    400149: 'Elegir',

    400150: 'Cómo funciona el P2P',
    400151: 'Comprar criptomonedas ',
    400152: 'Vender criptomonedas',
    400153: 'Haz tu pedido',
    400154: 'Después de realizar el pedido, los activos del vendedor para esa transacción serán gestionados por SuperEx P2P.',
    400155: 'Pago al vendedor',
    400156: 'Envíe dinero al vendedor a través del método de pago en la información de la transacción y haga clic en "Pagado, notificar al vendedor" en SuperEx P2P después de completar la transferencia.',
    400157: 'Consigue tu criptomoneda',
    400158: 'Una vez que el vendedor confirme la recepción del pago, se le concederá la moneda de custodia.',
    400159: 'Confirmar pago',

    400160: 'Comprueba el historial de transacciones en tu cuenta y asegúrate de haber recibido el pago del comprador.',
    400161: 'Enviar criptomoneda',
    400162: 'Envíe criptomonedas a los compradores en SuperEx P2P después de confirmar la recepción del pago.',
    400163: 'Cantidad',
    400164: 'Introduzca la cantidad',
    400165: 'Fiat',
    400166: 'Todos los métodos de pago',
    400167: 'ID del anuncio',
    400168: 'Confirmar',
    400169: '¿Estás seguro de que quieres eliminar el anuncio?',

    400170: '1. Los usuarios que eliminen anuncios no podrán comerciar con usted a través de anuncios',
    400171: '2. La eliminación de anuncios no afecta a los pedidos en curso',
    400172: '¿Estás seguro de que quieres eliminar este anuncio?',
    400173: 'Editar',
    400174: 'Eliminar',
    400175: 'Añadido',
    400176: 'Eliminado',
    400177: 'Creado',
    400178: 'Límite mínimo',
    400179: 'Cantidad restante/cantidad del pedido',

    400180: 'Precio del pedido',
    400181: 'Tipo',
    400182: 'Listado exitoso',
    400183: 'Todos los tipos',
    400184: 'Estatus',
    400185: 'ID/moneda/fecha del anuncio',
    400186: 'Funcionar',
    400187: 'De acuerdo con las especificaciones operativas del país o región donde está certificada su identidad SuperEx y la plataforma, elija la moneda legal compatible para la transacción.',
    400188: 'No cumple con los requisitos del anunciante',
    400189: 'Eliminado',

    400190: 'Eliminado con éxito',
    400191: 'Publicar anuncios',
    400192: 'Editar anuncio',
    400193: 'Precio fijo',
    400194: 'Precio flotante',
    400195: 'Porcentaje de variación de precios',
    400196: 'Por favor ingrese',
    400197: 'Precio',
    400198: 'Precio de referencia del mercado',
    400199: 'Establecer un porcentaje flotante le permite comprar y vender a un porcentaje por encima o por debajo del precio de referencia real del mercado',

    400200: 'Los precios fijos no cambian con las fluctuaciones del mercado',
    400201: 'Restricciones comerciales',
    400202: 'Introduzca el volumen de transacciones',
    400203: 'Número de operaciones',
    400204: 'Límite único mínimo',
    400205: 'Introduzca el límite mínimo de una sola transacción',
    400206: 'Límite único máximo',
    400207: 'Introduzca el límite máximo único',
    400208: 'Máx',
    400209: 'El anuncio requiere una tarifa de aproximadamente {rate}% {currency}, actualmente disponible {available} {currency}',

    400210: 'Método de pago',
    400211: 'Selecciona hasta 3',
    400212: 'No hay método de pago en este momento',
    400213: 'Elige el límite de tiempo de pago',
    400214: 'Introduce el contenido',
    400215: 'Condiciones del usuario comercial',
    400216: 'Aumentar el límite de usuarios comerciales reducirá las posibilidades de que se muestre su anuncio',
    400217: 'Registro completo',
    400218: 'días después',
    400219: 'Lanzar ahora',

    400220: 'Lanzar más tarde',
    400221: 'Divulgación de riesgos',
    400222: 'Debes introducir un número',
    400223: 'Elige el método de pago',
    400224: 'Introduzca el precio flotante',
    400225: 'Introduzca el precio fijo',
    400226: 'El precio se ha desviado del mercado {num}%, continuar publicando será arriesgado, por favor confirme si debe continuar publicando anuncios',
    400227: 'El límite único máximo debe ser mayor que el límite único mínimo',
    400228: 'El límite máximo único no debe ser superior a {num} {fiat}.',
    400229: 'El límite mínimo de una sola transacción no será inferior a {num} {fiat}',

    400230: 'El límite mínimo de orden único debe ser menor que el límite máximo de orden único',
    400231: 'El importe máximo de la transacción no debe ser superior a {num} {currency}.',
    400232: 'Publicación exitosa',
    400233: 'El comercio P2P no está abierto a los usuarios autorizados de la cartera en este momento, utilice su cuenta de SuperEx para iniciar sesión y comerciar con P2P',
    400234: 'Red DAO',
    400235: 'Roadmap',
    400236: 'Invitar amigos',
    400237: 'Centro de Ayuda',
    400238: 'Base Airdrop',
    400239: 'Explorar Mainnet',

    400240: 'Explorar Testnet',
    400241: 'Documentos del Desarrollador',
    400242: 'Centro de Eventos',
    400243: 'Al utilizar los sitios de SuperEx y/o mantener su cuenta, usted acepta nuestros <a style="color: inherit;text-decoration: underline;" target="_blank" href="https://support.superex.com/hc/es/articles/11564329223833">Términos de Servicio</a>, <a style="color: inherit;text-decoration: underline;" target="_blank" href="https://support.superex.com/hc/es/articles/11564228990233-Declaraci%C3%B3n-de-Privacidad">Declaración de Privacidad</a> y <a style="color: inherit;text-decoration: underline;" target="_blank" href="https://support.superex.com/hc/es/articles/11564228990233">Política de Cookies</a> actualizados. Al hacer clic en "X", reconoce que ha leído y aceptado los Términos y Políticas antes mencionados. ',
    400244: 'Comprar Tether | Obtener USDT | SuperEx P2P',
    400245: 'Centro de tareas',
    400246: 'Moneda',
    400247: 'Recibir',
    400248: 'Gastos',
    400249: 'Disponible',

    400250: 'Thanh toán',
    400251: 'Detalles del pedido',
    400252: 'Importe (incluidas las tarifas de transacción)',
    400253: 'Hora de llegada',
    400254: '≈{time} minutos',
    400255: 'Aviso',
    // 400256: '1.您将离开SUPEREX并进入第三方AlchemyPay网页以完成您的订单。',
    // 400257: '2. 订单完成后，您可以前往您的 <span class="pointer">财务记录</span> 查看详细信息。',
    // 400258: '3. 如果您需要付款方面的帮助，请联系付款提供商的 <span class="pointer">客户支持</span>。',
    // 400259: '4. 更多详情请参阅常见问题解答。 <span class="pointer">常问问题</span>',

    400260: 'He leído y acepto el descargo de responsabilidad',
    400261: '¿Cómo comprar criptomonedas en SuperEx?',
    400262: 'Cómo vender criptomonedas en SuperEx?',
    400263: 'Haga su pedido de criptomonedas',
    400264: 'Elige la moneda fiat con la que quieres pagar y la criptomoneda que quieres comprar. Introduzca la cantidad que desea gastar.',
    400265: 'Verifica tu identidad',
    400266: 'Complete la verificación KYC en SuperEx y el proveedor de pagos para completar el pago.',
    400267: 'Comprueba los detalles de tu pedido',
    400268: 'Compruebe su historial de depósitos para ver el estado de su pedido.',
    400269: '1. ¿Qué métodos de pago puedo usar para comprar criptomonedas?',

    400270: 'SuperEx actualmente es compatible con VISA, Mastercard, Apple Pay, Google Pay y otros métodos de pago. Los proveedores de servicios de terceros compatibles incluyen Mercuryo y alchemypay.',
    400271: '2. ¿Qué criptomonedas puedo comprar?',
    400272: 'SuperEx es compatible con las principales criptomonedas como BTC, ETH, USDT, LTC, USDC, XRP, BCH, CRV y TRX.',
    400273: '3. ¿SuperEx supervisa la calidad y los precios de los proveedores de servicios externos compatibles?',
    400274: 'SuperEx ha seleccionado cuidadosamente una serie de proveedores de servicios con reputación, popularidad y seguridad de confianza en la industria para proporcionar a los usuarios servicios optimizados.',
    400275: '4. ¿Qué tarifas tengo que pagar para comprar criptomonedas?',
    400276: 'Usted paga una tarifa al proveedor de servicios externo y una tarifa de transferencia en cadena. SuperEx no cobra ninguna tarifa.',
    400277: '5. ¿Cuánto tiempo se tarda en recibir la criptomoneda después del pago?',
    400278: 'Después de que se complete su pago en la plataforma de proveedores de servicios de terceros, su criptomoneda se depositará en su cuenta al contado en SuperEx en unos 2 a 10 minutos.',
    400279: '6. ¿Qué pasa si tengo problemas durante el proceso de compra?',

    400280: 'Póngase en contacto con el servicio de atención al cliente si tiene algún problema durante el proceso de transacción. Si no ha recibido la criptomoneda después de que se complete el pago, póngase en contacto con el proveedor de servicios externo para comprobar los detalles del pedido (este suele ser el método más eficiente). Debido a la propiedad intelectual de su región actual o a ciertas razones de política, tendrá que seleccionar la verificación humana.',
    400281: 'Límite de transacción única:',
    400282: 'Introducir volumen',
    400283: 'Proveedor de pagos',
    400284: 'Conseguir',
    400285: 'Equilibrio insuficiente',
    400286: "Dejarás SuperEx y saltarás a <span>{name}</span>, una institución financiera con licencia que proporciona capacidades de pago con tarjeta como un tercero. Cualquier evento de riesgo que ocurra durante el uso será soportado por <span>{name}</span>. Por favor, lea y acepte el <a class= 'pointer' target='_blank' href={server}>Acuerdo de Servicio</a> de <span>{name}</span> antes de usar sus servicios Si tiene alguna pregunta sobre el depósito con tarjeta de crédito, póngase en contacto con <a class='pointer' target='_blank' href={Customer}>{name} Servicio de Atención al Cliente</a> para obtener más detalles.",
    400287: 'No responsabilidad',
    400289: 'Cuenta spot disponible',
    400290: 'Confirmar',
    400291: 'Zona ET',
    400288: 'Finanzas',
    400292:'Cuenta financiera',

    'target_1': 'Spot',
    'target_2': 'Cuenta P2P',
    'target_3': '币本位合约账户',
    'target_4': 'USDⓢ-M',
    'target_5': '超级合约账户',
    'target_6': 'Finanzas - Interés disponible',


    400293: 'https://support.superex.com/hc/en-001/articles/23084120565017',
    400294: 'Comprar cripto',


    400295:'Por favor, abra el navegador Safari para usar Applepay',
    410000:'SCS Entrega por paracaídas',
    410001:'Agentes',

    410002:'Finanzas',
    100756: 'Ganar',
    100219: 'Activo',
    101154: 'Historial de suscripciones',



    122000:"Red Global DAO y Enlaces de Medios Sociales",
    122001:"Invitar Amigos para Obtener Recompensas",
    122002:"Plan de Desarrollo de SuperEx",
    122003:"Soluciones a Problemas Comunes Durante el Uso",
    122004:"Completar Tareas para Obtener Recompensas",
    122005:"Introducción a ET",
    122006:"Introducción a SuperEx",
    122007:"Rebaja por Referencia de Agentes",
    122008:"Baja Comisión en la Compra de Criptomonedas",
    122009:"Compras Rápidas de Criptomonedas",
    122010:"Mercado de Comercio de Criptomonedas",
    122011:"Mercado de Comercio al Contado",
    122012:"Mercado de Comercio de Futuros",
    122013:"Plataforma IEO para Proyectos de Alto Valor",
    122014:"Ahorrar y Gestionar Dinero para Obtener Ingresos",
    122015:"Compras a Precios Ultra Bajos",
    122016:"Centro de Actividades Emocionantes",
    122017:"Adelantarse en el Ecosistema BTC con Nuevas Monedas",
    122018:"Centro de Tareas de Airdrop de SCS",
    122019:"Sitio Web Oficial de SCS Chain",
    122020:"Navegador de SCS Chain",
    122021:"Navegador de la Red de Pruebas de SCS Chain",
    122022:"Documentación para Desarrolladores",
    122023:"Navegar por las últimas noticias",
    122024:"Obtener un resumen rápido de los últimos eventos",
    122025:"Verificar SuperEx en Youtube",
    122026:"Aprender todo sobre blockchain",
    122027:"Abre cajas misteriosas con tus amigos",
    122028: 'Caja misteriosa',
    
    //合约积分
    9100007: 'Puntos de Trading',
    9100031: 'Centro de Actividades de Puntos de Trading',

    9100032: 'Fondos cuantitativos',
    9100033: 'Gestión profesional de activos',

    8100000: 'Retroalimentación del usuario',


    ...ac_es,
    ...esLocal
}
export default es;
