import viLocal from 'element-ui/lib/locale/lang/vi'
import ac_vi from "./activity/ac_vi";

const vi = {
    400000: 'Học viện',
    400001: 'Tin tức',
    400002: 'Dữ liệu',
    400003: 'Video',
    400004: 'Học viện',
    400005: 'Đăng nhập',
    400006: 'Đăng ký',
    400007: 'Ví',
    400008: 'Tổng quan ví',
    400009: 'Spot',

    400010: 'Tài khoản P2P',
    400011: 'Futures',
    400012: 'Lịch sử ví',
    400013: 'Đơn hàng Spot',
    400014: 'Lệnh Futures',
    400015: 'Tổng quan',
    400016: 'Xác minh danh tính',
    400017: 'Bảo mật tài khoản',
    400018: 'Giới thiệu',
    400019: 'Ưu tiên',

    400020: 'Trung tâm phiếu thưởng',
    400021: 'Đăng xuất',
    400022: '<em class="normal">{num}</em>}Thông báo đang chờ xử lý',
    400023: 'Xóa tất cả',
    400024: 'Xem thêm',
    400025: 'Không có dữ liệu được tìm thấy',
    400026: 'Quét để tải xuống ứng dụng',
    400027: 'Tải xuống khác',
    400028: 'Chi tiết thông báo',
    400029: 'Tôi hiểu',

    400030: 'P2P',
    400031: 'Thị trường',
    400032: 'Spot',
    400033: 'Futures',
    400034: 'Super Start',
    400035: 'Địa chỉ không tồn tại',
    400036: 'Nền tảng giao dịch tài sản kỹ thuật số an toàn nhất trên thế giới！',
    400037: 'Về chúng tôi',
    400038: 'Điều kiện',
    400039: 'Sự riêng tư',

    400040: 'Thông báo',
    400041: '学习',
    400042: 'Trung tâm dành cho người mới bắt đầu',
    400043: 'Giao dịch Spot',
    400044: 'Futures',
    400045: 'FAQ',
    400046: 'Dịch vụ',
    400047: 'Phí',
    400048: 'Quy tắc',
    400049: 'Danh sách ứng dụng',

    400050: 'Giới thiệu',
    400051: 'Liên hệ chúng tôi',
    400052: 'Trung tâm trợ giúp',
    400053: 'Gửi yêu cầu',
    400054: 'Liên hệ công việc',
    400055: 'Thể chế hợp tác',
    400056: 'Ngôn ngữ và Khu vực',
    400057: 'Tiền tệ',
    400058: 'Chọn ngôn ngữ và khu vực',
    400059: 'Chọn tỷ giá giao dịch',

    400060: 'Chuyển ',
    400061: 'Chuyển tiền nội bộ SuperEx không tính phí',
    400062: 'Từ',
    400063: 'đến',
    400064: 'Cặp',
    400065: 'Phí chiếu khấu ',
    400066: 'Cross',
    400067: 'Isolated',
    400068: 'Split',
    400069: 'Số dư',

    400070: 'Tiền điện tử',
    400071: 'Tìm kiếm',
    400072: 'Số lượng',
    400073: 'Có sẵn',
    400074: 'Tất cả ',
    400075: 'Xác nhận chuyển ',
    400076: 'Mở',
    400077: 'Thử lại sau',
    400078: 'Mở tài khoản Futures',
    400079: 'Bạn chưa mở tài khoản hợp đồng Futures, vì vậy việc chuyển tiền tạm thời không khả dụng.',

    400080: 'Gửi lại sau {timeout} giây',
    400081: 'Gửi mã',
    400082: 'Tất cả tiền điện tử',
    400083: 'Tất cả các loại tiền tệ fiat',
    400084: 'Tất cả các trạng thái',
    400085: 'Khu vực tùy chọn',
    400086: 'Bên thứ ba',
    400087: 'Hướng dẫn',
    400088: 'Đặt hàng',
    400089: 'Trung tâm thương mại P2P',

    400090: 'Trung tâm người dùng P2P',
    400091: 'Phương thức thanh toán',
    400092: 'Đăng quảng cáo mới',
    400093: 'Quảng cáo của tôi',
    400094: 'Thêm nữa',
    400095: 'Ứng dụng',
    400096: 'Hủy bỏ',
    400097: 'Bạn tạm thời không thể đăng quảng cáo',
    400098: 'Đăng ký để trở thành nhà quảng cáo trước',
    400099: 'Được xác minh',

    400100: 'Chưa được xác minh',
    400101: 'Bạn chưa hoàn thành xác minh danh tính',
    400102: 'Để bảo mật tài khoản của bạn với những người dùng giao dịch khác, trước tiên vui lòng hoàn tất xác minh danh tính',
    400103: 'Đặt biệt hiệu',
    400104: 'Đặt tên tài khoản của bạn. Khuyến cáo không nên sử dụng tên thật. Độ dài biệt danh là 2~10 ký tự.',
    400105: 'Rất tiếc, bạn không thể đăng ký trở thành thương gia vào lúc này.',
    400106: 'Thiết lập thành công',
    400107: 'Lợi ích của SuperEx P2P',
    400108: '0 phí',
    400109: 'Trong SuperEx P2P, bạn có thể giao dịch bitcoin và các loại tiền kỹ thuật số khác một cách thuận tiện và nhanh chóng, đồng thời bên đặt lệnh giao dịch mà không mất phí.',

    400110: 'Phương thức thanh toán linh hoạt',
    400111: 'SuperEx P2P cho phép người bán tự do lựa chọn phương thức thanh toán.',
    400112: 'Giao dịch với giá tốt hơn',
    400113: 'SuperEx P2P cho phép người dùng đặt giá của riêng họ và chọn mức giá ưa thích để mua và bán tiền điện tử.',
    400114: 'Giao dịch tiền điện tử an toàn',
    400115: 'SuperEx P2P áp dụng cơ chế rút tiền mặt T+n, quản lý chặt chẽ người bán và sàng lọc các giao dịch đáng ngờ với dữ liệu lớn kiểm soát rủi ro để đảm bảo an toàn cho tài sản nền tảng và người dùng có thể giao dịch an toàn.',
    400116: '{amount} Âm lượng',
    400117: '{num} %Tỷ lệ giao dịch',
    400118: 'Giá bán',
    400119: 'Thời hạn thanh toán',

    400120: 'Phương thức thanh toán của người bán',
    400121: 'Điều khoản giao dịch',
    400122: 'Tôi sẽ trả',
    400123: 'Tôi se nhận',
    400124: 'Tôi muốn bán',
    400125: 'Phương thức thanh toán',
    400126: 'Thiết lập phương pháp thu thập',
    400127: 'Thêm phương thức thanh toán',
    400128: 'Đơn giá tham khảo {price}',
    400129: 'Mua',

    400130: 'Bán',
    400131: 'Để bảo vệ an toàn cho tiền, sẽ có giới hạn rút tiền {limit} đối với tài sản đã mua',
    400132: 'Tìm hiểu thêm',
    400133: 'Đồng ý',
    400134: 'Theo quốc gia của chứng chỉ của bạn và thông số kỹ thuật hoạt động của nền tảng, vui lòng chuyển sang nội tệ của bạn để giao dịch',
    400135: 'Giới hạn mua: {min}-{max} {fiat}',
    400136: 'Giới hạn bán: {min}-{max} {fiat}',
    400137: 'Nhà quảng cáo',
    400138: 'Giới hạn / Số lượng',
    400139: 'Giao dịch',

    400140: 'Số lượng',
    400141: 'Giới hạn',
    400142: 'Quá trình',
    400143: 'Đồng ý',
    400144: 'Trước khi bạn bắt đầu giao dịch, vui lòng hoàn thành xác minh danh tính',
    400145: 'Số lần hủy đơn hàng của bạn hôm nay đã vượt quá {num} lần và bạn không thể tiếp tục giao dịch. Vui lòng thử lại sau {time}',
    400146: 'Bạn đã đạt đến số lượng đơn hàng đang chờ xử lý tối đa ({num}), vui lòng hoàn thành đơn hàng đang xử lý trước.',
    400147: 'Nhà quảng cáo này đã đạt đến số lượng đơn đặt hàng đang chờ xử lý tối đa, vui lòng hoạt động sau hoặc thử một quảng cáo khác.',
    400148: 'Bạn chưa đáp ứng yêu cầu của nhà quảng cáo, vui lòng thử quảng cáo khác.',
    400149: 'Lựa chọn',

    400150: 'Cách thức hoạt động của P2P',
    400151: 'Mua tiền điện tử',
    400152: 'Bán tiền điện tử',
    400153: 'Đặt hàng của bạn',
    400154: 'Sau khi đặt hàng, tài sản của người bán cho giao dịch đó sẽ được quản lý bởi SuperEx P2P.',
    400155: 'Thanh toán cho người bán',
    400156: 'Gửi tiền cho người bán thông qua phương thức thanh toán trong thông tin giao dịch và nhấp vào "Đã thanh toán, thông báo cho người bán" trên SuperEx P2P sau khi hoàn tất chuyển khoản.',
    400157: 'Nhận tiền điện tử của bạn',
    400158: 'Sau khi người bán xác nhận đã nhận thanh toán, tiền lưu ký sẽ được cấp cho bạn.',
    400159: 'Xác nhận thanh toán',

    400160: 'Kiểm tra lịch sử giao dịch trong tài khoản của bạn và đảm bảo rằng bạn đã nhận được khoản thanh toán từ người mua.',
    400161: 'Gửi tiền điện tử',
    400162: 'Gửi tiền điện tử cho người mua trên SuperEx P2P sau khi xác nhận đã nhận thanh toán.',
    400163: 'Số lượng',
    400164: 'Nhập số tiền',
    400165: 'Fiat',
    400166: 'Tất cả các phương thức thanh toán',
    400167: 'ID quảng cáo',
    400168: 'Xác nhận',
    400169: 'Bạn có chắc chắn muốn xóa quảng cáo không?',

    400170: '1. Người dùng xóa quảng cáo sẽ không thể giao dịch với bạn thông qua quảng cáo',
    400171: '2. Việc xóa quảng cáo không ảnh hưởng đến các lệnh đang diễn ra',
    400172: 'Bạn có chắc chắn muốn xóa quảng cáo này không?',
    400173: 'Chỉnh sửa',
    400174: 'Xóa bỏ',
    400175: 'Thêm',
    400176: 'Loại bỏ',
    400177: 'Tạo',
    400178: 'Giới hạn tối thiểu',
    400179: 'Số tiền còn lại/Số tiền đặt lệnh',

    400180: 'Giá đặt hàng',
    400181: 'Loại hình',
    400182: 'Niêm yết thành công',
    400183: 'Tất cả loại',
    400184: 'Trạng thái',
    400185: 'ID quảng cáo/Tiền tệ/Ngày',
    400186: 'Vận hành',
    400187: 'Theo thông số kỹ thuật hoạt động của quốc gia hoặc khu vực nơi danh tính SuperEx của bạn được chứng nhận và nền tảng, vui lòng chọn loại tiền pháp định được hỗ trợ để giao dịch.',
    400188: 'Không đáp ứng yêu cầu của nhà quảng cáo',
    400189: 'Đã xóa',

    400190: 'Đã xóa thành công',
    400191: 'Đăng quảng cáo',
    400192: 'Chỉnh sửa quảng cáo',
    400193: 'Giá cố định',
    400194: 'Giá thả nổi',
    400195: 'Phần trăm thay đổi giá',
    400196: 'vui lòng nhập',
    400197: 'Giá giao dịch',
    400198: 'Giá tham khảo thị trường',
    400199: 'Đặt tỷ lệ phần trăm thả nổi cho phép bạn mua và bán theo tỷ lệ phần trăm cao hơn hoặc thấp hơn giá tham chiếu thực tế của thị trường',

    400200: 'Giá cố định không thay đổi theo biến động của thị trường',
    400201: 'Hạn chế giao dịch',
    400202: 'Nhập khối lượng giao dịch',
    400203: 'Số lượng giao dịch',
    400204: 'Giới hạn đơn tối thiểu',
    400205: 'Nhập giới hạn giao dịch đơn lẻ tối thiểu',
    400206: 'Giới hạn đơn tối đa',
    400207: 'Nhập giới hạn đơn tối đa',
    400208: 'Tối đa',
    400209: 'Quảng cáo yêu cầu một khoản phí khoảng {rate}% {currency}, hiện có sẵn {available} {currency}',

    400210: 'Phương thức thanh toán',
    400211: 'Chọn tối đa 3',
    400212: 'Hiện tại không có phương thức thanh toán',
    400213: 'Chọn thời hạn thanh toán',
    400214: 'Nhập nội dung',
    400215: 'Điều kiện người dùng giao dịch',
    400216: 'Việc tăng giới hạn người dùng giao dịch sẽ làm giảm cơ hội hiển thị quảng cáo của bạn',
    400217: 'Hoàn thành đăng ký',
    400218: 'ngày sau',
    400219: 'Khởi chạy ngay',

    400220: 'Khởi chạy sau',
    400221: 'Tuyên bố rủi ro',
    400222: 'Bạn phải nhập một số',
    400223: 'Chọn phương thức thanh toán',
    400224: 'Nhập giá thả nổi',
    400225: 'Nhập giá cố định',
    400226: 'Giá đã sai lệch so với thị trường {num}%, tiếp tục sẽ có rủi ro, vui lòng xác nhận có tiếp tục đăng quảng cáo hay không?',
    400227: 'Hạn mức đơn tối đa phải lớn hơn hạn mức đơn tối thiểu',
    400228: 'Giới hạn đơn tối đa không được cao hơn {num} {fiat}.',
    400229: 'Hạn mức giao dịch một lần tối thiểu không được ít hơn {num} {fiat}.',

    400230: 'Giới hạn đơn đặt hàng tối thiểu phải nhỏ hơn giới hạn đơn đặt hàng tối đa',
    400231: 'Số tiền giao dịch tối đa không được cao hơn {num} {currency}.',
    400232: 'Xuất thành công',
    400233: 'Giao dịch P2P không mở cho người dùng ví được ủy quyền tại thời điểm này, vui lòng sử dụng tài khoản SuperEx của bạn để đăng nhập và giao dịch P2P',
    400234: 'Mạng DAO',
    400235: 'Roadmap',
    400236: 'Mời bạn bè',
    400237: 'Trung tâm trợ giúp',
    400238: 'Căn cứ Airdrop',
    400239: 'Khám phá Mainnet',

    400240: 'Khám phá Testnet',
    400241: 'Tài liệu dành cho nhà phát triển',
    400242: 'Trung tâm tổ chức sự kiện',
    400243: 'Bằng cách sử dụng các trang web SuperEx và/hoặc duy trì tài khoản của mình, bạn đồng ý với <a style="color: inherit;text-decoration: underline;" target="_blank" href="https://support.superex.com/hc/vi/articles/11564490101145">Điều khoản dịch vụ</a>,<a style="color: inherit;text-decoration: underline;" target="_blank" href="https://support.superex.com/hc/vi/articles/11564388451865">Tuyên bố quyền riêng tư</a> và <a style="color: inherit;text-decoration: underline;" target="_blank" href="https://support.superex.com/hc/vi/articles/20760960364441">Chính sách Cookie</a> được cập nhật mới của chúng tôi. Bằng cách nhấp vào "X", bạn xác nhận rằng bạn đã đọc và đồng ý với các điều khoản và chính sách nói trên.',
    400244: 'Mua Tether | Nhận USDT | SuperEx P2P',
    400245: 'Trung tâm nhiệm vụ',
    400246: 'Tiền tệ',
    400247: 'Nhận',
    400248: 'Chi phí',
    400249: 'Khả dụng',

    400250: 'Thanh toán',
    400251: 'Chi tiết lệnh',
    400252: 'Số tiền (bao gồm phí giao dịch)',
    400253: 'Thời gian đến',
    400254: '≈{time} phút',
    400255: 'Chú ý',
    400256: '1.您将离开SUPEREX并进入第三方AlchemyPay网页以完成您的订单。',
    400257: '2. 订单完成后，您可以前往您的 <span class="pointer">财务记录</span> 查看详细信息。',
    400258: '3. 如果您需要付款方面的帮助，请联系付款提供商的 <span class="pointer">客户支持</span>。',
    400259: '4. 更多详情请参阅常见问题解答。 <span class="pointer">常问问题</span>',

    400260: 'Tôi đã đọc và đồng ý với tuyên bố từ chối trách nhiệm',
    400261: 'Làm cách nào để mua tiền điện tử trên SuperEx?',
    400262: 'Cách bán tiền điện tử trên SuperEx？',
    400263: 'Đặt lệnh tiền điện tử của bạn',
    400264: 'Chọn loại tiền pháp định bạn muốn thanh toán và tiền điện tử bạn muốn mua. Nhập số tiền bạn muốn chi tiêu.',
    400265: 'Xác minh danh tính của bạn',
    400266: 'Hoàn thành xác minh KYC trên SuperEx và nhà cung cấp dịch vụ thanh toán để hoàn tất thanh toán.',
    400267: 'Kiểm tra chi tiết lệnh của bạn',
    400268: 'Kiểm tra lịch sử gửi tiền của bạn để xem trạng thái lệnh của bạn.',
    400269: '1. Tôi có thể sử dụng phương thức thanh toán nào để mua tiền điện tử?',

    400270: 'SuperEx hiện hỗ trợ VISA, Mastercard, Apple Pay, Google Pay và các phương thức thanh toán khác. Các nhà cung cấp dịch vụ bên thứ ba được hỗ trợ bao gồm Mercuryo và alchemypay.',
    400271: '2. Tôi có thể mua loại tiền điện tử nào?',
    400272: 'SuperEx hỗ trợ các loại tiền điện tử phổ biến như BTC, ETH, USDT, LTC, USDC, XRP, BCH, CRV và TRX.',
    400273: '3. SuperEx có giám sát chất lượng và giá cả của các nhà cung cấp dịch vụ bên thứ ba được hỗ trợ không?',
    400274: 'SuperEx đã lựa chọn cẩn thận một số nhà cung cấp dịch vụ có uy tín, mức độ phổ biến và bảo mật đáng tin cậy trong ngành để cung cấp cho người dùng các dịch vụ hợp lý.',
    400275: '4. Tôi phải trả những khoản phí nào để mua tiền điện tử?',
    400276: 'Bạn trả phí cho nhà cung cấp dịch vụ bên thứ ba và phí chuyển tiền trên chuỗi. SuperEx không thu bất kỳ khoản phí nào.',
    400277: '5. Mất bao lâu để nhận được tiền điện tử sau khi thanh toán?',
    400278: 'Sau khi thanh toán của bạn hoàn tất trên nền tảng của nhà cung cấp dịch vụ bên thứ ba, tiền điện tử của bạn sẽ được gửi vào tài khoản giao ngay của bạn trên SuperEx trong khoảng 2–10 phút.',
    400279: '6. Nếu tôi gặp sự cố trong quá trình đặt lệnh thì sao?',

    400280: 'Liên hệ với bộ phận hỗ trợ khách hàng nếu bạn gặp bất kỳ vấn đề nào trong quá trình giao dịch. Nếu bạn chưa nhận được tiền điện tử sau khi thanh toán xong, hãy liên hệ với nhà cung cấp dịch vụ bên thứ ba để kiểm tra chi tiết đơn hàng (đây thường là phương pháp hiệu quả nhất). Do IP của khu vực hiện tại của bạn hoặc một số lý do chính sách nhất định, bạn sẽ phải chọn xác minh con người.',
    400281: 'Hạn mức giao dịch duy nhất:',
    400282: 'Nhập khối lượng',
    400283: 'Nhà cung cấp thanh toán',
    400284: 'Được',
    400285: 'Số dư không đủ',
    400286: "Bạn sẽ rời SuperEx và chuyển sang <span>{name}</span>, một tổ chức tài chính được cấp phép cung cấp khả năng thanh toán thẻ với tư cách là bên thứ ba. Mọi rủi ro xảy ra trong quá trình sử dụng sẽ do <span>{name}</span> chịu trách nhiệm. Vui lòng đọc và đồng ý với <a class= 'pointer' target='_blank' href={server}>Thỏa thuận dịch vụ</a> của <span>{name}</span> trước khi sử dụng dịch vụ của nó\n" + "Nếu bạn có bất kỳ câu hỏi nào về việc nạp tiền vào thẻ tín dụng, vui lòng liên hệ với <a class='pointer' target='_blank' href={Customer}>{name} dịch vụ khách hàng</a> để biết thêm chi tiết.",
    400287: 'Tuyên bố miễn trừ trách nhiệm',
    400289: 'Tài khoản Giao ngay có sẵn',
    400290: 'Xác nhận',
    400291: 'ET Zone',


    400293: 'https://support.superex.com/hc/en-001/articles/23084120565017',
    400294: 'Mua Crypto',

    400295:'Vui lòng mở trình duyệt safari để sử dụng Applepay',
    400288: 'Tài chính',

    400292:'Tài khoản tài chính',


    410000:'SCS Airdrop',
    410001:'Đại lý',


    410002:'Tài chính',
    100756: 'Earn',
    100219: 'Hoạt động',
    101154: 'Lịch sử đăng ký',



    122000:"Mạng DAO toàn cầu và các liên kết truyền thông xã hội",
    122001:"Mời bạn bè để nhận phần thưởng",
    122002:"Kế hoạch phát triển SuperEx",
    122003:"Giải pháp cho các vấn đề thường gặp trong quá trình sử dụng",
    122004:"Hoàn thành nhiệm vụ để nhận phần thưởng",
    122005:"Giới thiệu về ET",
    122006:"Giới thiệu về SuperEx",
    122007:"Giảm giá cho việc giới thiệu đại lý",
    122008:"Phí mua tiền điện tử thấp",
    122009:"Mua tiền điện tử nhanh chóng",
    122010:"Thị trường giao dịch tiền điện tử",
    122011:"Thị trường giao dịch giao ngay",
    122012:"Thị trường giao dịch tương lai",
    122013:"Nền tảng IEO cho các dự án có giá trị cao",
    122014:"Tiết kiệm tiền và quản lý tiền để kiếm thu nhập",
    122015:"Mua sắm giá cực thấp",
    122016:"Trung tâm hoạt động sôi động",
    122017:"Đi đầu trong hệ sinh thái BTC với tiền mới",
    122018:"Trung tâm tác vụ SCS Airdrop",
    122019:"Website chính thức của SCS Chain",
    122020:"Trình duyệt chuỗi SCS",
    122021:"Trình duyệt testnet SCS chain",
    122022:"Tài liệu dành cho nhà phát triển",
    122023:"Duyệt tin tức mới nhất",
    122024:"Tổng quan nhanh về các sự kiện mới nhất",
    122025:"Kiểm tra SuperEx trên Youtube",
    122026:"Tìm hiểu mọi thứ về blockchain",
    122027:"Mở hộp bí ẩn với bạn bè của bạn",
    122028: 'Phần thưởng Hộp bí ẩn',


    //合约积分
    9100007: 'Điểm giao dịch',
    9100031: 'Trung tâm hoạt động điểm giao dịch',

    9100032: 'Quỹ định lượng',
    9100033: 'Quản lý tài sản chuyên nghiệp',

    8100000: 'Phản hồi người dùng',

    'target_1': 'Spot',
    'target_2': 'Tài khoản P2P',
    'target_3': '币本位合约账户',
    'target_4': 'USDⓢ-M',
    'target_5': '超级合约账户',
    'target_6': 'Tài chính - Tiền lãi khả dụng',

    ...viLocal,
    ...ac_vi
}
export default vi;
