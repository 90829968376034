let urls = {
    baseUrl: '',
    socketUrl: '',
    staticState: '',
    linkUrl: '',
}; //这里是一个默认的url，可以没有
const hostArr = window.location.host.split('.')
var url = ''
if (hostArr.length == 3) {
    hostArr[0] = ''
    url = hostArr.join('.')
} else {
    url = '.' + hostArr.join('.')
}
switch (process.env.NODE_ENV) {
    case 'development': // 开发环境
        urls.baseUrl = "https://test-api.superex.bike"
        urls.socketUrl = 'wss://test-api.superex.bike/socket/ws'
        // urls.baseUrl = "https://nova-api.nova-x.ca"
        // urls.socketUrl = 'wss://nova-api.nova-x.ca/socket/ws'
        urls.staticState = 'https://img.superex.live'
        urls.linkUrl = 'http://localhost:8989'
        urls.nowUrl = 'http://' + window.location.host
        break
    case 'test': // 开发测试环境
        urls.baseUrl = "https://nova-api.nova-x.ca"
        urls.socketUrl = 'wss://nova-api.nova-x.ca/socket/ws'
        urls.staticState = 'https://img.superex.live'
        urls.linkUrl = 'https://dev.nova-x.ca'
        urls.nowUrl = 'https://' + window.location.host
        break
    case 'tests': // 测试站
        urls.baseUrl = "https://test-api.superex.bike"
        urls.socketUrl = 'wss://test-api.superex.bike/socket/ws'
        urls.staticState = 'https://img.superex.live'
        urls.linkUrl = 'https://www.superex.bike'
        urls.nowUrl = 'https://' + window.location.host
        break
    case 'production': //生产环境
        urls.baseUrl = 'https://api.superexchang.com'
        urls.socketUrl = 'wss://api.superexchang.com/socket/ws'
        urls.staticState = 'https://img.superex.live'
        urls.linkUrl = 'https://www' + url
        urls.nowUrl = 'https://' + window.location.host
        break
}

export default urls;
