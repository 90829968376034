<template>
    <div :class="$style['g-head']">
        <div :class="$style['g-headCont']" class="m-container">
            <div :class="[$style['container-inner'], $style[userInfoObj.type == 3 ? 'containerWellet' : '']]"
                class="grid items-center">
                <div :class="$style['state--pos-left']" class="flex justify-start items-center">
                    <a style="cursor: pointer;" :class="$style['nav-logo']" class="flex items-center" :href="linkUrl">
                        <img :class="$style['logo-img']" src="../assets/img/logo.png" alt="superex" />
                    </a>
                    <!-- <div :class="$style['nav-service-group']">
                        <div :class="$style['btn--show-service']">
                            <div :class="$style['dot-group']" class="grid grid-cols-3">
                                <template v-for="i in 3">
                                    <div v-for="j in 3" :key="i + '-' + j" :class="$style['dot']" :style="{
                                        '--delay': i + j - 2,
                                    }"></div>
                                </template>
                            </div>
                            <SiteNavSub :class="$style['service-nav']" distance="20px" :sub-nav-list="serviceNavList" />
                        </div>
                    </div> -->
                </div>
                <div :class="$style['state--pos-center']" style="width: 100%;" class="w-full flex justify-center">
                    <SiteNav :headType="true" :class="$style['site-nav']" :nav-list="siteNavList"
                        class="grid grid-flow-col-dense" />
                </div>
                <div :class="$style['state--pos-right']" class="flex justify-end items-center">
                    <div :class="$style['btn-group--text']" class="grid" v-if="!token">
                        <!-- 登录 -->
                        <button @click="toLink(`${linkUrl}/login?isP2P=1&historyBack=${locationHref}`)"
                            :class="$style['btn-item']" class="flex justify-center items-center">
                            <span :class="$style['text']">{{ $t('400005') }}</span>
                        </button>
                        <!-- 注册 -->
                        <button @click="toLink(`${linkUrl}/register?isP2P=1&historyBack=${locationHref}`)"
                            :class="$style['btn-item']" class="flex justify-center items-center" data-type="register">
                            <span :class="$style['text']">{{ $t('400006') }}</span>
                        </button>
                    </div>

                    <div :class="$style['btn-group--text']" class="grid" v-if="token">
                        <!-- 钱包 -->
                        <button :class="$style['btn-item']" data-type="Wallet" class="flex justify-center items-center">
                            <span :class="$style['text']"> {{ $t('400007') }}</span>
                            &nbsp;
                            <UeIcon :class="$style['ic']" name="arrow-down" />
                            <SiteNavSub :headType="true" :width="'auto'" :class="$style['service-nav']" distance="8px"
                                :sub-nav-list="welletList" />
                        </button>
                        <!-- 订单 -->
                        <!-- <button :class="$style['btn-item']" data-type="Wallet" class="flex justify-center items-center">
                            <span :class="$style['text']"> {{ $t('100011') }}</span>
                            &nbsp;
                            <UeIcon :class="$style['ic']" name="arrow-down" />
                            <SiteNavSub :width="'auto'" :class="$style['service-nav']" distance="8px"
                                :sub-nav-list="orderList" />
                        </button> -->
                        <!-- 账号密码用户信息 -->
                        <div :class="$style['btn-group--icon']" style="margin: 0;">
                            <button :class="$style['btn-item']" data-type="userInfor"
                                class="flex justify-center items-center">
                                <Exicon name="icon-my_night" />
                                <SiteNavSub :headType="true" :type="true" :width="'250px'" :class="$style['service-nav']"
                                    distance="8px" :sub-nav-list="UserList" />
                            </button>
                        </div>
                    </div>

                    <div :class="$style['btn-group--icon']" class="flex items-center">
                        <button v-if="item.isShow" v-for="item in iconBtns" :key="item.type" :class="$style['btn-item']"
                            class="flex justify-center items-center" :data-type="item.type" @click="fireOper(item.type)">
                            <div v-if="item.type === 'notification'">
                                <el-badge :value="unreadNum" :max="99" v-if="unreadNum">
                                    <Exicon :name="item.icon" />
                                </el-badge>
                                <Exicon v-else :name="item.icon" />
                            </div>
                            <Exicon v-else :name="item.icon" />
                            <!-- 下载二维码 -->
                            <template v-if="item.type === 'downloadApp'">
                                <div :class="$style['download-info']">
                                    <div :class="$style['inner']">
                                        <div :class="$style['code-box']">
                                            <div :class="$style['qrcodeCopy']" id="qrcodeCopy"></div>
                                        </div>
                                        <div :class="$style['title']">
                                            <span class="text">{{ $t('400026') }}</span>
                                        </div>
                                        <a :class="$style['download-btn']" :href="`${linkUrl}/download`">
                                            <span class="text">
                                                {{ $t('400027') }}
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </template>
                            <!-- 站内信 -->
                            <template v-if="item.type === 'notification'">
                                <div
                                    :class="[$style['message-list'], styleType == 'sunlight' ? $style['message-list_them'] : '']">
                                    <div :class="$style['message-cont']">
                                        <div :class="$style['flex-between']" class="flex-between">
                                            <div :class="$style['flex-center']" class="flex-center">
                                                <p :class="$style['inline-block']" class="inline-block"
                                                    v-html="$t('400022', { num: unreadNum })">
                                                </p>
                                                <!--清除全部-->
                                                <!-- <el-button type="text" @click="handleDeleteAll">{{ $t('100101')
                                                    }}</el-button> -->
                                            </div>
                                            <!--查看更多-->
                                            <a :href="`${linkUrl}/notice`">
                                                {{ $t('400024') }}
                                                <i :class="isDirection ? 'el-icon-arrow-left' : 'el-icon-arrow-right'"></i>
                                            </a>
                                        </div>
                                        <div :class="$style['list-item']" class="list-item pointer"
                                            v-for="(item, index) in messageList" :key="index + 'c'"
                                            @click="viewDetail(item)">
                                            <div :class="[$style['msg-title'], $style['text-overflow']]"
                                                class="msg-title pointer text-overflow">
                                                <span :class="{ sign: item.status == 0 }" class="relative"
                                                    v-show="item.type == 1">
                                                    <!-- <i class="inline-block"></i> -->
                                                    <svg class="icon" aria-hidden="true">
                                                        <use xlink:href="#icon-xitongtongzhi" />
                                                    </svg>
                                                </span>
                                                <span :class="[$style['relative'], { sign: item.status == 0 }]"
                                                    class="relative" v-show="item.type == 2">
                                                    <i class="inline-block"></i>
                                                    <svg class="icon" aria-hidden="true">
                                                        <use xlink:href="#icon-gerentongzhi" />
                                                    </svg>
                                                </span>
                                                {{ item.title }}
                                            </div>
                                            <div :class="$style['msg-content']" class="msg-content">
                                                <p v-html="item.content"></p>
                                                <span :class="[$style['time'], $style['block']]" class="block time">{{
                                                    parseTime(item.pushTime) }}</span>
                                            </div>
                                        </div>
                                        <el-empty :description="$t('400025')" v-show="messageList.length == 0">
                                            <svg slot="image" class="icon empty-icon" aria-hidden="true">
                                                <use xlink:href="#icon-jilu2" />
                                            </svg>
                                        </el-empty>
                                    </div>
                                </div>
                            </template>
                        </button>
                    </div>
                    <!-- 侧边栏导航 -->
                    <div ref="openFoldNavBtn" :class="$style['btn--open-fold-nav']" :data-is-open="foldNavIsOpen"
                        class="hidden" @click.self="openFoldNav">
                        <div :class="[$style['btn--inner'], styleType === 'sunlight' ? $style['btn--them'] : '']">
                            <div :class="$style['line']" :style="{ '--delay': 0 }"></div>
                            <div :class="$style['line']" :style="{ '--delay': 1 }"></div>
                            <div :class="$style['line']" :style="{ '--delay': 2 }"></div>
                        </div>
                        <SiteNavFold class="hidden" :headType="true" v-if="foldNavIsOpen" :class="$style['site-nav--fold']"
                            :nav-list="siteNavList" @closeFoldNav="foldNavIsOpen = false" />
                    </div>
                </div>
            </div>
        </div>
        <!-- 通知详情 -->
        <div class="model" v-show="detailModel">
            <div :class="$style['detail-model']" class="detail-model">
                <!--通知详情-->
                <div :class="[$style['flex-between'], $style['bold']]" class="flex-between bold">
                    {{ $t('400028') }}
                    <i class="el-icon-close pointer" @click="detailModel = !detailModel"></i>
                </div>
                <div>
                    <p :class="[$style['title'], $style['bold']]" class="title bold">{{ detailObj.title }}</p>
                    <span :class="[$style['block'], $style['time']]" class="block time">{{ parseTime(detailObj.pushTime)
                    }}</span>
                    <p :class="$style['content']" class="content" v-html="detailObj.content"></p>
                </div>
                <!--知道了-->
                <el-button :class="[$style['bold'], $style['el-button']]" class="bold"
                    @click="detailModel = !detailModel">{{
                        $t('300083')
                    }}</el-button>
            </div>
        </div>
        <!-- 切换语言汇率 -->
        <Language :NewHome="NewHome" :languageModel="languageModel" @closeFun="languageModel = false" />
    </div>
</template>
<script>
import mitt from "mitt";
import _throttle from "lodash/throttle";
import SiteNav from "../components/NewHome/SiteNav.vue";
import SiteNavSub from "../components/NewHome/SiteNavSub.vue";
import { checkIsAgentState } from '@/apis/agent.js'
import { chainConfig } from '@/apis/wallet'
import $ from 'jquery';
import { mapGetters } from 'vuex'
import Language from './publicModel/Language'
// import chooseNetwork from './walletConnect/chooseNetwork'
import urls from '@/utils/baseUrl'
import QRcode from 'qrcodejs2'
import {
    unreadList,
    getNoticeList,
    readMsg,
    removeAllMsg,
    getDomains,
} from '@/apis/user'
import {
    telephoneShow,
    emailShow,
    parseTime,
    ellipseAddress,
} from '@/plugins/methods'
import SiteNavFold from "../components/NewHome/SiteNavFold.vue";
import { setCookie, getCookie } from '../utils/common'
const mittManage = mitt()
export default {
    name: 'home',
    components: {
        SiteNavSub,
        SiteNav,
        Language,
        // chooseNetwork,
        SiteNavFold
    },
    props: {
        NewHome: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            languageModel: false, //多语言显示隐藏
            networkModel: false, // 钱包登录显示隐藏
            messageList: [], // 站内信列表
            detailObj: {}, //站内详情信息
            detailModel: false, //站内信详情弹窗
            unreadNum: 0,
            textBtns: [
                { type: "login", text: "Login" },
                { type: "register", text: "Register" },
                { type: "connectWallet", text: "Connect Wallet" },
            ],
            foldNavIsOpen: false,
            publicPath: "",
            networkList: [],
            linkUrl: urls.linkUrl,
            locationHref: urls.nowUrl + '/thirdParty',
            linkData:[
                {
                    text:'立即進行P2P交易',
                    link:'https://p2p.superex.com/buy_USDT',
                },
                {
                    text:'Trade P2P now',
                    link:'https://p2p.superex.com/buy_USDT',
                },
                {
                    text:'立即交易現貨',
                    link:'https://www.superex.com/trade/BTC_USDT',
                },
                {
                    text:'Trade spot now',
                    link:'https://www.superex.com/trade/BTC_USDT',
                },
                {
                    text:'立即交易合約',
                    link:'https://www.superex.com/contract/BTC_USDT',
                },
                {
                    text:'Trade futures now',
                    link:'https://www.superex.com/contract/BTC_USDT',
                },
                {
                    text:'立即搶購',
                    link:'https://www.superex.com/activity/SuperStart',
                },
                {
                    text:'Buy now',
                    link:'https://www.superex.com/activity/SuperStart',
                },
                {
                    text:'領取您的專享獎勵',
                    link:'https://www.superex.com/noviceGuide/sevenDay',
                },
                {
                    text:'Receive your exclusive rewards',
                    link:'https://www.superex.com/noviceGuide/sevenDay',
                },
                {
                    text:'立即充值',
                    link:'https://www.superex.com/wallet/spot',
                },
                {
                    text:'Deposit now',
                    link:'https://www.superex.com/wallet/spot',
                }
            ]
        }
    },
    watch: {
        $route(to, from) {

        }
    },
    computed: {
        ...mapGetters([
            'look',
            'token',
            'userInfo',
            'levelConfig',
            'styleType',
            'language',
            'isDirection',
            'invitationCode'
        ]),
        userInfoObj() {
            return this.userInfo ? this.userInfo : new Object
        },
        isIndex() {
            console.log(this.$route.name == "index")
            return this.$route.name == "index"
        },
        walletConfig() {
            const walletConfig = JSON.parse(
                localStorage.getItem('walletConfig')
            )
            return walletConfig ? walletConfig : false
        },
        orderList() {
            return [
                {
                    title: this.$t('100016'),  //现货订单
                    link: "/order/trade/current",
                    type: 1
                },
                {
                    title: this.$t('100018'),  //合约订单
                    link: "/order/swap/current",
                    type: 1
                },
                {
                    title: this.$t('101154'),  //认购历史
                    link: "/order/SuperStart/history",
                    type: 1,
                },
            ]
        },
        UserList() {
            return [
                {
                    icon: "icon-zonglan2",
                    title: this.$t('400015'),  //总览
                    link: "/userCenter/overview",
                    type: 1
                },
                {
                    icon: "icon-xianhuodingdan",
                    title: this.$t('400013'),  //现货订单
                    link: "/order/trade/current",
                    type: 1
                },
                {
                    icon: "icon-heyuedingdan",
                    title: this.$t('400014'),  //合约订单
                    link: "/order/swap/current",
                    type: 1
                },
                {
                    icon: "icon-rengoujilu",
                    title: this.$t('101154'),  //认购历史
                    link: "/order/SuperStart/history",
                    type: 1,
                },
                {
                    icon: "icon-shenfenrenzheng2",
                    title: this.$t('400016'),  //身份认证
                    link: '/userCenter/complete',
                    type: 6
                },
                {
                    icon: "icon-zhanghuanquan1",
                    title: this.$t('400017'),  //账户安全
                    link: "/userCenter/account",
                    type: 1,
                    isShow: this.userInfoObj.type == 3
                },
                {
                    icon: "icon-fanyong1",
                    title: this.$t('400018'),  //返佣
                    link: "/userCenter/commission",
                    type: 1,
                    isShow: this.userInfoObj.type == 3
                },
                {
                    icon: "icon-pianhaoshezhi1",
                    title: this.$t('400019'),  //偏好设置
                    link: "/userCenter/preferences",
                    type: 1,
                    isShow: this.userInfoObj.type == 3
                },
                {
                    icon: "icon-kajuanzhongxin",
                    title: this.$t('400020'),  //卡卷中心
                    link: "/userCenter/couponCenter",
                    type: 1
                },
                {
                    icon: "icon-tuichudenglu",
                    title: this.$t('400021'),  //退出登录
                    link: "",
                    type: 8,
                    isShow: this.userInfoObj.type == 3
                },
            ]
        },
        serviceNavList() {
            return [
                {
                    icon: "market",
                    title: this.$t('400234'),  //DAO 网络
                    desc: "This is the navigation subtitle text placeholder",
                    link: "/dao",
                    type: 1
                },
                {
                    icon: "futures",
                    title: this.$t('400236'), //邀请好友
                    desc: "This is the navigation subtitle text placeholder",
                    link: "/userCenter/commission",
                    type: 1
                },
                {
                    icon: "spot",
                    title: this.$t('400235'), //Roadmap
                    desc: "This is the navigation subtitle text placeholder",
                    link: "/roadmap",
                    type: 1
                },
                {
                    icon: "futures",
                    title: this.$t('400237'), //帮助中心
                    desc: "This is the navigation subtitle text placeholder",
                    link: this.language == 'zh-TC'
                        ? 'https://support.superex.com/hc/zh-hk'
                        : this.language == 'ko'
                            ? 'https://support.superex.com/hc/ko'
                            : 'https://support.superex.com/hc/en-001',
                    type: 2
                },
                {
                    icon: "futures",
                    title: this.$t('400245'), //任务中心
                    desc: "This is the navigation subtitle text placeholder",
                    link: "/noviceGuide/sevenDay",
                    type: 1
                },
                {
                    icon: "futures",
                    title: this.$t('400291'), // ET 专区
                    desc: "This is the navigation subtitle text placeholder",
                    link: "/et",
                    type: 1
                },
                {
                    icon: "futures",
                    title: this.$t('410001'), // 代理商
                    desc: "This is the navigation subtitle text placeholder",
                    link: "",
                    type: 4
                },
            ]
        },
        siteNavList() {
            return [
                {
                    title: this.$t('400294'), //快捷买币
                    link: "",
                    sub: [
                        {
                            icon: "icon-P2P3",
                            title: "P2P", // P2P
                            desc: this.$t('122008'),
                            link: `/`,
                            name:"coin",
                            type: 3
                        },
                        {
                            icon: "icon-a-Third-Party",
                            title: this.$t('400086'), // 三方支付
                            desc: this.$t('122009'),
                            name:"thirdParty",
                            link: `/thirdParty`,
                            type: 3
                        },
                    ],
                },
                {
                    title: this.$t('300111'), //交易
                    link: "",
                    sub: [
                        {
                            icon: "icon-nacicon",
                            title: this.$t('400031'), // 行情
                            desc: this.$t('122010'),
                            link: "/markets",
                            type: 1
                        },
                        {
                            icon: "icon-Spot",
                            title: this.$t('400032'), //现货
                            desc: this.$t('122011'),
                            link: "/trade/BTC_USDT",
                            type: 1
                        },
                        {
                            icon: "icon-nacicon-1",
                            title: this.$t('400033'), //合约
                            desc: this.$t('122012'),
                            link: "/contract/BTC_USDT",
                            type: 1
                        },
                    ],
                },
                {
                    title: this.$t('410002'), //金融
                    link: "",
                    sub: [
                        {
                            icon: "icon-a-SuperStart",
                            title: this.$t('400034'), // Super Start
                            desc: this.$t('122013'),
                            link: "/activity/SuperStart",
                            type: 1
                        },
                        {
                            icon: "icon-Earn",
                            title: this.$t('100756'), //理财
                            desc: this.$t('122014'),
                            link: "/sortMoney/sortMoneyIndex",
                            type: 1
                        },
                        {
                            icon: "icon-a-jijin1",
                            title: this.$t('9100032'), //量化基金  9100032
                            desc: this.$t('9100033'),
                            link: "/quantitativeFunds",
                            type: 1
                        },
                    ],
                },
                {
                    title: this.$t('100219'), //活动
                    link: "",
                    sub: [
                        {
                            icon: "icon-a-1USD",
                            title: "1USD", // 1USD
                            desc: this.$t('122015'),
                            link: "/1USD",
                            type: 1
                        },
                        {
                            icon: "icon-a-EventCenter",
                            title: this.$t('400242'), //活动中心
                            desc: this.$t('122016'),
                            link: "/events",
                            type: 1
                        },
                    ],
                },
                { title: "BTC Startup", link: "/markets?btcStartup=1" },//
                {
                    title: "SCS",
                    link: "",
                    sub: [
                        {
                            icon: "icon-jiaoyijifen",
                            title: this.$t('9100007'), //合约交易积分
                            desc: this.$t('9100031'),
                            link: "/contractPoint",
                            type: 1
                        },
                        // {
                        //     icon: "icon-a-SCSAairdrop",
                        //     title: this.$t('410000'), //SCS空投
                        //     desc: this.$t('122018'),
                        //     link: "/task",
                        //     type: 1
                        // },
                        {
                            icon: "icon-a-SCSChain",
                            title: "SCS Chain", //SCS 官网
                            desc: this.$t('122019'),
                            link: "https://scschain.com",
                            type: 2
                        },
                        {
                            icon: "icon-a-language_day",
                            title: this.$t('400239'), //SCS 主网浏览器
                            desc: this.$t('122020'),
                            link: "https://scschain.com/stayTuned.html#module-contac",
                            type: 2
                        },
                        {
                            icon: "icon-a-TestnetExplorer",
                            title: this.$t('400240'), //SCS 测试网浏览器
                            desc: this.$t('122021'),
                            link: "https://testnetscan.scschain.com",
                            type: 2
                        },
                        {
                            icon: "icon-a-DeveloperDocs",
                            title: this.$t('400241'), //SCS 开发者文档
                            desc: this.$t('122022'),
                            link: "https://superexchain.github.io",
                            type: 2
                        }
                    ],
                },
                { title: "Space", link: "" },//
                // {
                //     title: this.$t('400000'), //学院
                //     link: "",
                //     sub: [
                //         {
                //             icon: "icon-News",
                //             title: this.$t('400001'), //新闻
                //             desc: this.$t('122023'),
                //             link: "/academy/news",
                //             type: 1
                //         },
                //         {
                //             icon: "icon-Date",
                //             title: this.$t('400002'), //数据
                //             desc: this.$t('122024'),
                //             link: "/academy/data",
                //             type: 1
                //         },
                //         {
                //             icon: "icon-Videos",
                //             title: this.$t('400003'), //视频
                //             desc: this.$t('122025'),
                //             link: "",
                //             type: 5
                //         },
                //         {
                //             icon: "icon-Learn",
                //             title: this.$t('400004'), //学习
                //             desc: this.$t('122026'),
                //             link: "/academy/learn",
                //             type: 1
                //         },
                //     ],
                // }
            ]
        },
        welletList() {
            return [
                {
                    title: this.$t('400008'),  //钱包总览
                    link: "/wallet/walletotal",
                    type: 1
                },
                {
                    title: this.$t('400009'),  //现货账户
                    link: "/wallet/spot",
                    type: 1
                },
                {
                    title: this.$t('400010'),  //P2P账户
                    link: "/wallet/P2P",
                    type: 1,
                    isShow: this.userInfoObj.type == 3
                },
                {
                    title: this.$t('400011'),  //合约账户
                    link: "/wallet/contract",
                    type: 1
                },
                {
                    title: this.$t('400292'),  //金融账户
                    link: "/wallet/financialAccounts",
                    type: 1
                },
                {
                    title: this.$t('400012'),  //钱包历史记录
                    link: "/wallet/historical",
                    type: 1
                },
            ]
        },
        iconBtns() {
            return [
                { type: "notification", icon: "icon-message_night", isShow: this.token },
                { type: "downloadApp", icon: "icon-download_day", isShow: true },
                { type: "changeLang", icon: "icon-a-language_day", isShow: true },
                { type: "sunlight", icon: this.styleType == 'sunlight' ? 'icon-baitian' : 'icon-heiye', isShow: this.sunlightShow },
            ]
        },
        sunlightShow() {
            console.log(this.$route)
            let RouterArr = ['/1USD', '/myRecord', '/prizeDetail', '/noviceGuide/sevenDay']
            return RouterArr.indexOf(this.$route.path) !== -1 ? false : true
        }
    },
    async created() {
        if (this.token) {
            this.$store.dispatch('getLevelConfigApi')
            this.getNoticeList()
            this.$store.dispatch('GetC2CUserInfo')
            this.unreadList()
        }
    },
    methods: {
        // 跳转
        toLink(href) {
            window.location.href = href
        },
        // 查看通知详情
        viewDetail(item) {
            if (item.status == 0) {
                this.readMsg(item.id)
            }
            this.detailObj = item
            this.detailModel = true
        },
        readMsg(id) {
            readMsg(id)
                .then((res) => {
                    if (res.code == 200) {
                        // this.$notify({
                        //     message: res.msg,
                        //     iconClass: 'iconfont icon-wancheng-2',
                        //     customClass: 'successIcon flex-center',
                        //     showClose: false,
                        //     duration: 2000,
                        //     position: this.IsShow() ? 'top-left' : 'top-right',
                        // })
                        this.unreadList()
                        this.getNoticeList()
                    }
                })
                .catch(() => { })
        },
        // 通知数量
        unreadList() {
            unreadList().then((res) => {
                if (res.data) {
                    this.unreadNum = res.data.num
                }
            })
        },
        // 通知列表
        getNoticeList() {
            var data = {
                page: 1,
                pageSize: 2,
            }
            getNoticeList(data).then((res) => {
                if (res.data && res.data.items.length > 0) {
                    res.data.items.forEach((v) => {
                        v.content = this.getHref(v.content)
                    })
                    this.messageList = res.data.items
                }
            })
        },
        parseTime(time) {
            return parseTime(time)
        },
        toLinkText(text){
            let data = this.linkData.filter(item=>text.includes(item.text))
            if(data.length){
                data.forEach(item=>{
                    text = text.replace(item.text,`<a target="_self" class="pointer" href="${item.link}" style="color: #FFC72B">${item.text}</a>`)
                })
            }
            return text
        },
        // 识别超链接
        getHref(content) {
            if (!content) {
                return ''
            }
            const urlPattern =
                /(https?:\/\/|www\.)[a-zA-Z_0-9\-@]+(\.\w[a-zA-Z_0-9\-:]+)+(\/[()~#&\-=?+%/.\w]+)?/g
            content = content.replace(urlPattern, (match) => {
                const urlHttp =
                    match.indexOf('http://') && match.indexOf('https://')
                // const url = urlHttp === -1 ? match.split('/')[0] : match.split('/')[2];
                const href = urlHttp === -1 ? `https://${match}` : match
                return `<a target="_blank" href="${href}" style="color: #FFC72B">${href}</a>`
            })
            return this.toLinkText(content)
        },
        // 钱包断开连接
        handleWalletLogout() {
            this.$refs.chooseNetworkModel.walletDisconnect()
        },
        // 字符串截取
        ellipseAddressCopy(str) {
            if (!str) {
                return ''
            } else {
                return `${str.slice(0, 3)}......${str.slice(-3)}`
            }
        },
        // // 退出登录
        // handleWalletLogout() {
        //     this.$refs.chooseNetworkModel.walletDisconnect()
        // },
        // 滑动监听
        checkPageScroll() {
            const scrollTop = $("body").scrollTop() || 0;
            const hasScroll = scrollTop > 0;
            $("body").attr("data-has-scroll", hasScroll ? "1" : "0");
            this.foldNavIsOpen = false;
        },
        // 获取二维码
        getDomains() {
            getDomains().then((res) => {
                this.domain = res.data
                this.qrcode()
            })
        },
        // 生成二维码
        qrcode() {
            let invitationCode = this.invitationCode ? '?'+`invitationCode=${this.invitationCode}` : ''
            var _this = this
            let qrcode = new QRcode('qrcodeCopy', {
                width: 130,
                height: 130, // 高度
                text: _this.domain + `/download${invitationCode}`, // 二维码内容
            })
        },
        openFoldNav() {
            this.foldNavIsOpen = !this.foldNavIsOpen
        },
        fireOper(type) {
            switch (type) {
                case "changeLang":
                    this.languageModel = true
                    break;
                case "sunlight":
                    this.changeTheme()
                    break;
                default:
                    break;
            }
        },
        changeTheme() {
            if (this.styleType == 'sunlight') {
                this.$store.dispatch('setStyleType', 'moon')
                setCookie('styleType', 'moon')
                window.document.documentElement.setAttribute(
                    'data-theme',
                    'moon'
                )
            } else {
                this.$store.dispatch('setStyleType', 'sunlight')
                setCookie('styleType', 'sunlight')
                window.document.documentElement.setAttribute(
                    'data-theme',
                    'sunlight'
                )
            }
        },
    },
    mounted() {
        const openFoldNavBtn = this.$refs.openFoldNavBtn
        this.checkPageScroll();
        // 滚动行为管理
        const _throttleWatch = _throttle(this.checkPageScroll, 100);
        $("body").on("scroll", _throttleWatch);
        // 点击行为管理
        $("body").on("click", (ev) => {
            if (!openFoldNavBtn?.contains(ev.target)) {
                this.foldNavIsOpen = false;
            }
        });

        mittManage.on("destroy", () => {
            $("body").removeAttr("data-has-scroll");
            $("body").off("scroll", _throttleWatch);
        });
        // =============
        this.getDomains()
    },
    destroyed() {
    },
    beforeDestroy() {
        mittManage.emit("destroy");
        mittManage.all.clear();
    }
}
</script>

<style lang="scss" module>
@import "../assets/css/uemo-scss/scss/abstracts/mixins";
@import '../assets/css/variable.scss';
@import '../assets/css/mixin';
[data-theme="sunlight"] {
    body[data-has-scroll="1"] {
    .g-head {
        background-color: rgba(255, 255, 255, .9);

        &::after {
            opacity: 1;
        }
    }
}
}
[data-theme="moon"]{
    body[data-has-scroll="1"] {
    .g-head {
        background-color: rgba(0, 0, 0, 0);

        &::after {
            opacity: 1;
        }
    }

}
}

[dir='ltr'] .g-head {
    .message-list {
        left: -320px;

        .msg-content {
            padding-left: 36px;

            .msg-title {
                svg {
                    margin-right: 10px;
                }
            }

            span.time {
                text-align: left;
            }
        }
    }
}

[dir='rtl'] .g-head {
    .nav-logo {
        margin: 0 0 0 32px;
    }

    .message-list {
        right: -320px;

        .msg-title {
            svg {
                margin-left: 10px;
            }
        }

        .msg-content {
            padding-right: 36px;

            span.time {
                text-align: right;
            }
        }
    }

    .download-info {
        right: -100px !important;
    }

    .btn-group--icon {
        margin-right: 12px;
        margin-left: 0;
    }

    .btn--open-fold-nav {
        margin-right: 12px;
        margin-left: 0;
    }

    .site-nav--fold {
        right: -230px !important;
    }
}

.g-head {
    --g--container-width: 100%;
    --g--container-space: 2%;
    position: sticky;
    z-index: var(--g--layer--z-index--lv1);
    top: 0;
    left: 0;
    padding: 12px 0;
    // transition: 0.16s ease;
    @include background_color('headBackground');
    border-bottom: 1px solid;
    @include border_color('border-color-bottom');

    &::after {
        position: absolute;
        z-index: 0;
        top: 0;
        left: 50%;
        overflow: hidden;
        width: 100%;
        height: 100%;
        content: "";
        transition: 0.26s ease;
        transform: translateX(-50%);
        @include background_color('headBackgroundAffter');
        pointer-events: none;
        opacity: 0;
        backdrop-filter: blur(25px);
    }

    .container-inner {
        position: relative;
        transition: 0.36s ease;
        grid-template-columns: 390px 1fr 390px;

        &.containerWellet {
            grid-template-columns: 290px 1fr 500px;
        }

        &>* {
            position: relative;
            z-index: var(--g--z-index);
        }

        &>.state--pos-left,
        &>.state--pos-right {
            top: 0;
            white-space: nowrap;
            transition: 0.36s ease;
        }
    }



    .nav-logo {
        margin-right: 32px;

        .logo-img {
            height: 28px;
        }
    }

    .btn--show-service {
        position: relative;

        cursor: pointer;

        .dot-group {
            gap: 4px;
            width: 20px;
        }

        .dot {
            @include circle(4px, rgba(var(--g--color-font--default), 1));
            transition: 0.12s ease;
            transition-delay: calc(var(--delay) * 0.06s);
        }

        &:hover {
            .dot {
                background-color: rgba(var(--g--color-theme--main), 1);
            }

            .service-nav {
                --nav-sub--inner-y: 0;
                --opacity: 1;

                visibility: visible;

                pointer-events: initial;

                opacity: 1;
            }
        }
    }

    .btn-group--text {
        font-size: 13px;

        gap: 8px;
        grid-auto-flow: column;

        .btn-item {
            position: relative;
            padding: 6px 12px;
            border: 2px solid transparent;
            @include font_color('font-color-2');

            .text {
                display: flex;
                align-items: center;
            }

            .chain-list {
                position: absolute;
                min-width: 120px;
                width: 100%;
                left: calc(50% - var(--width) / 2);
                transition: 0.26s ease;
                top: 100%;
                visibility: hidden;
                opacity: 0;
                z-index: 3;
                padding-top: 8px;

                .chain-cont {
                    background: rgba(22, 23, 25, 0.8);
                    box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.03), 0 12px 16px -4px rgba(16, 24, 40, 0.08);
                    -webkit-backdrop-filter: blur(25px);
                    backdrop-filter: blur(25px);
                    border: 1px solid rgba(234, 236, 240, 0.2);
                    border-radius: 12px;

                    &.chain-cont_them {
                        background: rgba(255, 255, 255, 0.8);

                        p {
                            color: #40475A !important;

                            &:hover {
                                color: rgba(var(--g--color-theme--main), 1) !important;
                            }
                        }
                    }

                    p {
                        padding: 12px;
                        color: #FFF;
                        height: auto;
                        line-height: normal;
                        border-bottom: 1px solid rgba(255, 255, 255, 0.05);

                        &:hover {
                            color: rgba(var(--g--color-theme--main), 1);
                        }

                        img {
                            max-width: 18px;
                        }

                        &:last-child {
                            border: none;
                        }
                    }
                }
            }

            &[data-type="register"] {
                border-color: #FFC630;
                border-radius: 100px;
            }

            &[data-type="connectWallet"] {
                border-color: #FFC630;
                border-radius: 100px;
                background-color: #FFC630;

                .text {
                    color: #333;
                }
            }

            &[data-type="chain"] {
                border-color: #FFC630;
                border-radius: 100px;

                &:hover {
                    .text {
                        color: rgba(var(--g--color-theme--main), 1);
                    }

                    .ic {
                        color: rgba(var(--g--color-theme--main), 1);
                    }

                    .chain-list {
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }

            &[data-type="WalletUser"] {
                border-radius: 100px;
                @include background_color('background-color-14');

                &:hover {
                    .text {
                        color: rgba(var(--g--color-theme--main), 1);
                    }

                    .ic {
                        color: rgba(var(--g--color-theme--main), 1);
                    }

                    .service-nav {
                        --nav-sub--inner-y: 0;
                        --opacity: 1;
                        visibility: visible;
                        pointer-events: initial;
                        opacity: 1;
                    }
                }
            }

            &[data-type="Wallet"] {
                position: relative;
                z-index: var(--g--z-index);

                &:hover {
                    .text {
                        color: rgba(var(--g--color-theme--main), 1);
                    }

                    .ic {
                        color: rgba(var(--g--color-theme--main), 1);
                    }

                    .service-nav {
                        --nav-sub--inner-y: 0;
                        --opacity: 1;
                        visibility: visible;
                        pointer-events: initial;
                        opacity: 1;
                    }
                }
            }
        }
    }

    .btn-group--icon {
        font-size: 20px;

        margin-left: 12px;

        color: rgba(var(--g--color-font--default), 0.3);

        gap: 8px;

        i {
            font-size: 20px
        }

        .btn-item {
            @include circle(32px);
            position: relative;
            transition: 0.26s ease;

            &[data-active],
            &:hover {
                color: rgba(var(--g--color-font--default), 1);
                @include background_color('background-color-13');

                i {
                    @include font_color('font-color-9');
                }
            }

            i {
                @include font_color('font-color-1');
            }

            &[data-type="notification"]:hover {
                .message-list {
                    visibility: visible;

                    padding-top: 10px;

                    pointer-events: initial;

                    opacity: 1;
                }
            }

            &[data-type="downloadApp"]:hover {
                .download-info {
                    visibility: visible;

                    padding-top: 10px;

                    pointer-events: initial;

                    opacity: 1;
                }
            }

            &[data-type="userInfor"]:hover {
                .service-nav {
                    visibility: visible;

                    padding-top: 10px;

                    pointer-events: initial;

                    --opacity: 1;
                }
            }
        }

        // 站内信
        .message-list {
            position: absolute;
            top: 100%;
            left: -320px;
            visibility: hidden;
            transition: 0.26s ease;
            pointer-events: none;
            opacity: 0;

            &.message-list_them {
                .message-cont {

                    background: rgba(255, 255, 255, 0.8) !important;

                    div.flex-between {
                        background-color: rgba(0, 0, 0, 0.1);

                        p.inline-block {
                            color: #40475A !important;
                        }

                        .el-button--text {
                            color: #40475A !important;
                            padding: 0;
                        }

                        a {
                            color: #40475A !important;
                        }
                    }

                    .list-item {
                        margin-bottom: 16px;

                        @supports (background-clip: text) {
                            .msg-title {
                                background: linear-gradient(90deg,
                                        rgba(var(--g--color-theme--main), 1) 0%,
                                        #ffde68 50%,
                                        #000922 50%);
                                transition: 0.3s ease;
                                background-position: 100% 0;
                                background-clip: text;
                                background-size: 200% 100%;
                                -webkit-text-fill-color: transparent;
                            }

                            &:hover {
                                .msg-title {
                                    transition-delay: 0.08s;
                                    background-position: 0 0;
                                }
                            }
                        }

                        @supports not (background-clip: text) {

                            .msg-title {
                                transition: 0.3s ease;
                            }

                            &:hover {

                                .msg-title {
                                    color: rgba(var(--g--color-theme--main), 1);
                                }
                            }
                        }
                    }

                    .msg-content {
                        p {
                            color: #000922 !important;
                        }

                        span {
                            color: #000922 !important;
                        }
                    }
                }
            }

            // 黑版
            .message-cont {
                width: 400px;
                height: auto;
                padding: 22px 17px;
                border-radius: 12px;
                background: rgba(22, 23, 25, 0.8);
                box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.03), 0 12px 16px -4px rgba(16, 24, 40, 0.08);
                -webkit-backdrop-filter: blur(25px);
                backdrop-filter: blur(25px);
                border: 1px solid rgba(234, 236, 240, 0.2);

                div.flex-between {
                    padding: 15px 16px;
                    font-size: $font-size-small;
                    background-color: rgba(255, 255, 255, 0.1);
                    line-height: normal;
                    border-radius: 4px;
                    margin-bottom: 13px;

                    p.inline-block {
                        color: $font-color-white4;
                    }

                    .el-button--text {
                        color: $font-active-color;
                        padding: 0;
                    }

                    a {
                        color: $font-color-white;
                    }
                }

                .list-item {
                    margin-bottom: 16px;

                    @supports (background-clip: text) {
                        .msg-title {
                            background: linear-gradient(90deg,
                                    rgba(var(--g--color-theme--main), 1) 0%,
                                    #ffde68 50%,
                                    #fff 50%);
                            transition: 0.3s ease;
                            background-position: 100% 0;
                            background-clip: text;
                            background-size: 200% 100%;
                            -webkit-text-fill-color: transparent;
                        }

                        &:hover {
                            .msg-title {
                                transition-delay: 0.08s;
                                background-position: 0 0;
                            }
                        }
                    }

                    @supports not (background-clip: text) {

                        .msg-title {
                            transition: 0.3s ease;
                        }

                        &:hover {

                            .msg-title {
                                color: rgba(var(--g--color-theme--main), 1);
                            }
                        }
                    }

                    .msg-title {
                        color: $font-color-white;
                        font-size: $font-size-normal;
                        line-height: 1.5;
                        display: flex;
                        align-items: center;

                        svg {
                            width: 24px;
                            height: 24px;
                            margin-right: 10px;
                        }

                        .sign i {
                            width: 8px;
                            height: 8px;
                            display: block;
                            border-radius: 50%;
                            background-color: #f65d62;
                            position: absolute;
                            top: 0;
                            right: 10px;
                        }
                    }

                    .msg-content {
                        color: $font-color-white6;
                        font-size: $font-size-small;
                        line-height: 1.5;
                        width: 100%;
                        padding-left: 36px;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 3;
                        overflow: hidden;
                        text-align: left;

                        p {
                            width: 100%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }

                        span.time {
                            color: $font-color-white4;
                            font-size: $font-size-mini;
                            line-height: 1.5;
                            margin-top: 12px;
                        }
                    }
                }
            }
        }

        // 下载样式
        .download-info {
            font-size: 12px;
            position: absolute;
            top: 100%;
            right: -50px;
            visibility: hidden;
            padding-top: 20px;
            transition: 0.26s ease;
            pointer-events: none;
            opacity: 0;

            .inner {
                padding: 18px 18px 13px;

                border-radius: 6px;
                background-color: #fff;
            }

            .title {
                margin-top: 5px;
                padding: 5px 0;

                text-align: center;

                color: rgba(var(--g--color-font--reverse), 1);
                border-bottom: 1px solid rgba(#000, 0.1);
            }

            .code-box {
                @include image-placeholder-v4(130, 130);
                width: 130px;
                margin: 0 auto;
            }

            .qrcodeCopy {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }

            .download-btn {
                display: block;

                margin-top: 10px;
                padding: 8px 6px;

                color: #fff;
                border-radius: 4px;
                background-color: rgba(var(--g--color-theme--main), 1);
            }
        }
    }

    .detail-model {
        width: 638px;
        padding: 32px;
        @include background_color('dialog-bg');
        border-radius: 4px;
        height: auto;
        max-height: 400px;
        overflow-y: auto;

        .flex-between {
            font-size: 18px;
            @include font_color('font-color');

            i {
                font-size: $font-size-normal;
            }
        }

        .title {
            margin-top: 32px;
            font-size: $font-size-normal;
            @include font_color('font-color');
        }

        span.time {
            font-size: $font-size-small;
            @include font_color('font-color-1');
            margin: 8px 0 16px;
        }

        .content {
            font-size: $font-size-small;
            @include font_color('font-color-2');
            line-height: 1.5;
            white-space: pre-wrap;
        }

        .el-button {
            padding: 8px 72px;
            background-color: $button-bg;
            font-size: $font-size-small;
            color: $font-color-3;
            border: none;
            display: block;
            margin: 60px auto 0;
        }
    }
}

@media (max-width: 1680px) {

.logo-img {
    height: 24px !important;
}

.btn-group--icon {
    gap: 2px !important;
}

.btn-item {
    font-size: 12px;
    padding: 4px 8px !important;

    i {
        font-size: 16px !important;
    }
}
}

@media (max-width: 1600px) {

    // .g-head {
    //     .g-headCont {
    //         zoom: 83%;
    //     }
    // }

    // @-moz-document url-prefix() {

    .logo-img {
        height: 24px !important;
    }

    .btn-group--icon {
        gap: 6px !important;
    }

    .btn-item {
        font-size: 11px;
        padding: 4px 9px !important;

        i {
            font-size: 16px !important;
        }
    }

    .container-inner {
        // grid-template-columns: 220px 1fr 400px !important;
    }

    // }

    body[data-has-scroll="1"] {
        .container-inner {
            grid-template-columns: 310px 1fr 390px;
        }
    }
}

@media (max-width: 1440px) {

    // .g-head {
    //     .g-headCont {
    //         zoom: 83%;
    //     }
    // }

    // @-moz-document url-prefix() {

    .logo-img {
        height: 20px !important;
    }

    .btn-group--icon {
        gap: 3px !important;
    }

    .btn-item {
        font-size: 11px;
        padding: 4px 8px !important;

        i {
            font-size: 14px !important;
        }
    }

    .container-inner {
        grid-template-columns: 310px 1fr 390px !important;
        // grid-template-columns: 220px 1fr 400px !important;
    }

    // }

    body[data-has-scroll="1"] {
        .container-inner {
            grid-template-columns: 310px 1fr 390px;
        }
    }
}

@media (max-width: 1366px) {
    .logo-img {
        height: 20px !important;
    }

    .btn-group--icon {
        gap: 3px !important;
    }

    .btn-item {
        font-size: 11px;
        padding: 4px 6px !important;

        i {
            font-size: 14px !important;
        }
    }

    .container-inner {
        grid-template-columns: 220px 1fr 300px !important;
    }

    // }

    body[data-has-scroll="1"] {
        .container-inner {
            grid-template-columns: 310px 1fr 390px;
        }
    }
}

@media (max-width: 1280px) {
    .logo-img {
        height: 18px !important;
    }

    .btn-group--icon {
        gap: 3px !important;
    }

    .btn-item {
        font-size: 11px;
        padding: 4px 6px !important;

        i {
            font-size: 14px !important;
        }
    }

    .container-inner {
        grid-template-columns: 100px 1fr 300px !important;
        // grid-template-columns: 220px 1fr 400px !important;
    }
}

@media (min-width: 1024px) {
    .site-nav--fold {
        display: none !important;
    }
}

@media (max-width: 1024px) {
    .g-head {
        .state--pos-center {
            display: none;
        }

        .container-inner {
            min-height: 58px;

            grid-template-columns: 1fr 1fr !important;
        }

        .btn--open-fold-nav {
            @include square(32px);
            position: relative;

            display: flex;

            margin-left: 12px;

            cursor: pointer;

            align-items: center;
            justify-content: center;

            &.hidden {
                display: none;
            }

            .btn--inner {
                position: relative;

                width: 20px;
                height: 2px;

                pointer-events: none;

                &.btn--them {
                    .line {
                        background-image: linear-gradient(to right,
                                rgba(var(--g--color-theme--main), 1) 0%,
                                rgba(var(--g--color-theme--main), 1) 50%,
                                #7F8490 50%) !important;
                    }
                }
            }

            &:hover {
                .line {
                    background-position: 0%;
                }
            }

            &[data-is-open] {
                .line {
                    background-position: 0%;
                }

                .site-nav--fold {
                    --nav-fold--inner-y: 0;
                    --opacity: 1;
                    visibility: visible;
                    display: block !important;
                }
            }

            .line {
                position: absolute;
                left: 0;

                overflow: hidden;

                width: 100%;
                height: 100%;

                transition: 0.36s ease;
                transition-delay: calc(var(--delay) * 0.1s);

                border-radius: 4px;
                background-image: linear-gradient(to right,
                        rgba(var(--g--color-theme--main), 1) 0%,
                        rgba(var(--g--color-theme--main), 1) 50%,
                        #fff 50%);
                background-position: 100%;
                background-size: 200% 100%;

                &:first-child {
                    top: -6px;
                }

                &:nth-child(2) {
                    top: 0;
                }

                &:last-child {
                    bottom: -6px;
                }
            }

            .site-nav--fold {
                position: absolute;
                top: 100%;
                right: -30px;
            }
        }
    }
}</style>
